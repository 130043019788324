import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { store } from '@/store'
import { Auth } from '@/plugins/nicotto'
import ApplicationIndexView from '../views/application/IndexView.vue'
import ApplicationSendMailView from '../views/application/SendMailView.vue'
import ApplicationConfirmView from '../views/application/ConfirmView.vue'
import ApplicationCompleteView from '../views/application/CompleteView.vue'
import ForgotIndexView from '../views/forgot/IndexView.vue'
import ForgotSendMailView from '../views/forgot/SendMailView.vue'
import ForgotConfirmView from '../views/forgot/ConfirmView.vue'
import ForgotCompleteView from '../views/forgot/CompleteView.vue'
import SigninView from '../views/SigninView.vue'
import DashboardView from '../views/DashboardView.vue'
import UsersIndexView from '../views/users/IndexView.vue'
import UsersEditView from '../views/users/EditView.vue'
import AccountView from '../views/AccountView.vue'
import AccountConfirmView from '../views/AccountConfirmView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import AnalyticsView from '../views/AnalyticsView.vue'
import ConciergeRoutes from './concierge'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'dashboard',
    meta: { requiresAuth: true },
    component: DashboardView
  },
  {
    path: '/application',
    name: 'application',
    meta: { requiresAuth: false },
    children: [
      { path: '', name: 'application-index', component: ApplicationIndexView },
      { path: 'sendmail', name: 'application-send-mail', component: ApplicationSendMailView },
      { path: 'confirm', name: 'application-confirm', component: ApplicationConfirmView },
      { path: 'complete', name: 'application-complete', component: ApplicationCompleteView },
    ],
  },
  {
    path: '/forgot',
    name: 'forgot',
    children: [
      { path: '', name: 'forgot-index', component: ForgotIndexView },
      { path: 'sendmail', name: 'forgot-send-mail', component: ForgotSendMailView },
      { path: 'confirm', name: 'forgot-confirm', component: ForgotConfirmView },
      { path: 'complete', name: 'forgot-complete', component: ForgotCompleteView }
    ],
  },
  {
    path: '/signin',
    name: 'signin',
    component: SigninView,
    beforeEnter: () => {
      if (store.getters.isLogin) {
        return store.getters.isAdmin ? '/users' : '/'
      }
    }
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
    children: [
      { path: '', name: 'users-index', component: UsersIndexView},
      { path: ':userId', name: 'users-edit', component: UsersEditView, props: true },
    ],
  },
  {
    path: '/account',
    name: 'account',
    meta: {
      requiresAuth: true,
      title: 'ユーザ編集'
    },
    component: AccountView
  },
  {
    path: '/account/confirm',
    name: 'account-confirm',
    meta: {
      requiresAuth: true,
      title: '確認コード入力'
    },
    component: AccountConfirmView
  },
  {
    path: '/analytics',
    name: 'analytics',
    meta: {
      requiresAuth: true,
      title: 'アナリティクス'
    },
    component: AnalyticsView
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundView
  },
  {...ConciergeRoutes},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    }
    return { top: 0 }
  }
})

router.beforeEach(async(to) => {
  const user = await Auth.getCurrentUser()
  store.commit('setUser', user)
  // 認証画面に未認証状態でアクセスした場合はログイン画面に誘導
  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isLogin) {
    return '/signin'
  // 一般ユーザは管理者用画面にアクセス不可
  } else if (!store.getters.isAdmin && to.matched.some(record => record.meta.requiresAdmin)) {
    return { name: 'not-found', replace: true }
  // 管理者用チェック
  } else if (store.getters.isAdmin) {
    // 自治体情報が設定されていない場合、ユーザ一覧を表示
    if (!store.state.municipality && !to.matched.some(record => record.meta.requiresAdmin)) {
      console.log('[ROUTER] municipality not set.')
      return '/users'
    }
  }
  if (store.state.message?.dismiss === false) {
    store.commit('setShowAlert', false)
  }
})

export default router
