export const getAccount = /* GraphQL */ `
  query getAccount($id: ID!, $sortKey: ID!) {
    getMunicipality(id: $id, sortKey: $sortKey) {
      id
      sortKey
      name
      type
      orderBy
      services {
        concierge
      }
      tel
      prefecture
      path
      corporateNumber
      code
      department
      url
      note
      createdAt
      updatedAt
    }
  }
`

export const listMunicipalities = /* GraphQL */ `
  query listMunicipalities(
    $type: String!
    $orderBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMunicipalityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMunicipalityByType(
      type: $type
      orderBy: $orderBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sortKey
        name
        type
        orderBy
        tel
        prefecture
        path
        corporateNumber
        code
        url
        note
        services {
          concierge
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const listUsers = /* GraphQL */ `
  query listUsers(
    $type: String!
    $orderBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMunicipalityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMunicipalityByType(
      type: $type
      orderBy: $orderBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sortKey
        name
        type
        orderBy
        tel
        department
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`