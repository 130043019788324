<template>
  <div class="container-fluid">
    <footer class="footer d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
      <div id="copy">
        <p lang="en" xml:lang="en" class="mb-0 text-muted">Copyright &copy; <a href="https://www.futureinn.co.jp/" class="text-muted">FUTUREINN CO</a>.,LTD. All rights reserved. </p>
      </div>


      <ul class="nav col-md-4 justify-content-end">
        <li class="nav-item"><a href="https://www.faq.nicotto-admin.jp/faq/nicotto_無償トライアル利用規約［初版］.pdf" class="nav-link px-2 text-muted" target="_blank">利用規約<img src="~@/assets/img/icon/up-right-from-square.svg" class="external" alt="新規ウインドウで開く" title="新規ウインドウで開く"></a></li>
        <li class="nav-item"><a href="https://www.futureinn.co.jp/corporate/certification/personalinformation.html" class="nav-link px-2 text-muted" target="_blank">個人情報保護への取り組み<img src="~@/assets/img/icon/up-right-from-square.svg" class="external" alt="新規ウインドウで開く" title="新規ウインドウで開く"></a></li>
      </ul>
    </footer>
  </div>
</template>

<script setup lang="ts">


</script>