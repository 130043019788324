import Papa from 'papaparse'
import { format } from 'util'

export function createCsv(data: Array<any>, columns: object) {
  const bom = new Uint8Array([0xEF, 0xBB, 0xBF])
  const csv = Object.values(columns).join(',') + '\r\n'
            + Papa.unparse(data, { header: false, columns: Object.keys(columns) })
  return new Blob([bom, csv], { type: 'text/csv' })
}

export function parseCsv(csv: string, columns: string[]) {
  csv = Object.values(columns).join(',') + '\r\n'
      + csv.trimEnd() // 末尾の空白(空行)除去
  return Papa.parse(csv, { header: true })
}

export abstract class CsvImporter {
  maxErrorLength = 10

  constructor(protected data: Array<any>, protected header?: any) {}

  public validate() {
    const errors: string[] = []

    // ヘッダー
    this.validateHeader(this.header, errors)
    if (errors.length > 0) {
      return errors
    }

    // データ行
    let rowNo = 1
    for (const item of this.data) {
      rowNo++
      if (this.skipRow(item)) {
        continue
      }
      this.validateFields(item, rowNo, errors)
      if (errors.length >= this.maxErrorLength) {
        errors.push(format('エラー件数が%s件を超えたので、以降の処理を中断しました。', this.maxErrorLength))
        break
      }
    }
    this.postValidate(errors)
    return errors
  }

  public abstract import(): Promise<void>

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  protected validateHeader(header: any, errors: string[]) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  protected postValidate(errors: string[]) {}

  protected abstract validateFields(item: any, rowNo: number, errors: string[]): void

  protected skipRow(item: object) {
    let skip = true
    Object.values(item).forEach(val => {
      if (val !== '') {
        skip = false
      }
    })
    return skip
  }
}