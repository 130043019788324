import { App, inject, InjectionKey } from 'vue'

/**
 * メッセージの種類(BootstrapのAlertの色に対応)
 */
export const MessageVariant = {
  DANGER: 'danger',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info'
} as const
export type MessageVariantType = typeof MessageVariant[keyof typeof MessageVariant]

export interface Message {
  text: string,
  variant: MessageVariantType
  dismiss?: boolean
  to?: string
}

export class Message implements Message {
  constructor(message: string, options?: { variant?: MessageVariantType, dismiss?: boolean, to?: string }) {
    this.text = message
    this.variant = options?.variant || MessageVariant.DANGER
    this.dismiss = options?.dismiss === false ? false : true
    if (options?.to) {
      this.to = options.to
    }
  }
}
export class SuccessMessage extends Message {
  constructor(message: string, options: { dismiss?: boolean, to?: string } = {}) {
    super(message, { ...options, variant: 'success'})
  }
}

export class WarningMessage extends Message {
  constructor(message: string, options: { dismiss?: boolean, to?: string } = {}) {
    super(message, { ...options, variant: 'warning'})
  }
}

export class InfoMessage extends Message {
  constructor(message: string, options: { dismiss?: boolean, to?: string } = {}) {
    super(message, { ...options, variant: 'info'})
  }
}

export class ErrorMessage extends Message {
}

/**
 * メッセージの定義
 */
const messages = {
  validation: {
    invalidTelPattern: '%sはハイフン(-)を含めてください。',
    invalidPasswordPattern: '%sは8文字以上の英数字・記号で入力してください。',
    isNotImage: '画像ファイルを選択してください。',
    fileIsRequired: 'ファイルを選択してください',
    isNotCSV: 'CSVファイルを選択してください'
  },
  concierge: {
    validation: {
      invalidPageIdPattern: '%sは半角英数字、ハイフン(-)、アンダーバー(_)のみ使用できます。',
      invalidDepartmentCodePattern: '%sは半角英数字、ハイフン(-)、アンダーバー(_)のみ使用できます。数字のみの場合は0以外から始めてください。',
      invalidDepartmentOrderSeqPattern: '%sは0以上の整数で入力してください。',
    },
    preview: {
      publishFailed: new ErrorMessage('プレビューページの生成に失敗しました。'),
      publishFailedByProcess: new ErrorMessage('プレビューページの生成に失敗しました。質問データに使用された「手続き」が後から削除された可能性があります。'),
      publishFailedByContact: new ErrorMessage('プレビューページの生成に失敗しました。質問データに使用された「その他の手続き可能な窓口」が後から削除された可能性があります。'),
      generateUrlFailed: new ErrorMessage('プレビューURLの発行に失敗しました。'),
    },
    processes: {
      saveSucceeded: new SuccessMessage('手続きを登録しました。'),
      saveFailed: new ErrorMessage('手続きの登録に失敗しました。'),
      saveFailedAlreadyExists: new ErrorMessage('この手続き名はすでに登録されているため、使用できません。'),
      updateSucceeded: new SuccessMessage('手続きを更新しました。'),
      updateFailed: new ErrorMessage('手続きの更新に失敗しました。'),
      deleteSucceeded: new SuccessMessage('手続きを削除しました。'),
      deleteFailed: new ErrorMessage('手続きの削除に失敗しました。'),
      importSucceeded: new SuccessMessage('手続きのインポートが完了しました。'),
      importFailed: new ErrorMessage('手続きのインポートに失敗しました。'),
      importValidation: {
        isProcessNull: '%s行目の手続きを入力してください。',
        isDepartmentNull: '%s行目の担当課を入力してください。',
        isLinkUrlNull: '%s行目の関連リンク名を入力した場合は関連リンクのURLも入力してください。',
        isLinkNameNull: '%s行目の関連リンクのURLを入力した場合は関連リンク名も入力してください。',
        invalidLinkUrlPattern: '%s行目のURLの形式が正しくありません。',
        invalidDepartmentPattern: '%s行目の担当課が既存データと一致しません。',
        isDuplicated: '%sの手続き名が重複しています。',
      }
    },
    departments: {
      saveSucceeded: new SuccessMessage('担当課を登録しました。'),
      saveFailed: new ErrorMessage('担当課の登録に失敗しました。'),
      saveFailedAlreadyExists: new ErrorMessage('この担当課コードはすでに登録されています。'),
      updateSucceeded: new SuccessMessage('担当課を更新しました。'),
      updateFailed: new ErrorMessage('担当課の更新に失敗しました。'),
      deleteSucceeded: new SuccessMessage('担当課を削除しました。'),
      deleteFailed: new ErrorMessage('担当課の削除に失敗しました。'),
      importSucceeded: new SuccessMessage('担当課のインポートが完了しました。'),
      importFailed: new ErrorMessage('担当課のインポートに失敗しました。'),
      importValidation: {
        isCodeNull: '%s行目の担当課コードを入力してください。',
        invalidCodePattern: '%s行目の担当課コードは、半角英数字、ハイフン(-)、アンダーバー(_)のみ使用できます。数字のみの場合は0以外から始めてください。',
        isNameNull: '%s行目の担当課名を入力してください。',
        invalidTelPattern: '%s行目の電話番号はハイフン(-)を含めてください。',
        invalidUrlPattern: '%s行目のURLの形式が正しくありません。',
        isDuplicated: '%sの担当課コードが重複しています。',
        isNotNumber: '%s行目の結果表示順は0以上の整数を入力してください。',
      }
    },
    categories: {
      saveSucceeded: new SuccessMessage('カテゴリを登録しました。'),
      saveFailed: new ErrorMessage('カテゴリの登録に失敗しました。'),
      saveFailedAlreadyExists: new ErrorMessage('この画面IDはすでに登録されています。'),
      updateSucceeded: new SuccessMessage('カテゴリを更新しました。'),
      updateFailed: new ErrorMessage('カテゴリの更新に失敗しました。'),
      deleteSucceeded: new SuccessMessage('カテゴリを削除しました。'),
      deleteFailed: new ErrorMessage('カテゴリの削除に失敗しました。'),
      importSucceeded: new SuccessMessage('カテゴリのインポートが完了しました。'),
      importFailed: new ErrorMessage('カテゴリのインポートに失敗しました。'),
      importValidation: {
        sections: {
          isEmpty: '［質問］%s行目のセクション行が連続しています。セクションには必ず質問行を追加してください。',
          invalidSectionNoPattern: '［質問］%s行目のセクション番号は半角数字で入力してください。',
          sequenceError: '［質問］%s行目のセクション番号を前のセクション番号からの連番に変更してください。',
          startNoError: '［質問］%s行目のセクション番号を1に変更してください。',
          startRowError: '［質問］%s行目のセクション行を先頭行（3行目）に移動してください。',
          isNameNull: '［質問］%s行目の種別を入力してください',
        },
        questions: {
          isNull: '［質問］%s行目の質問を入力してください。',
          isCopiedSection: '［質問］%s行目の質問タイプを「上と同じ」以外に変更してください。セクション行の連続は使用できません。',
          isCopiedNull: '［質問］%s行目の質問タイプを「質問なし」に変更してください。',
          isCopiedNoRow: '［質問］%s行目の質問タイプを「上と同じ」以外に変更してください。先頭行の場合はセクションを設定してください。',
          overSkipYesSectionNo: '［質問］%s行目の「「はい」を選択した場合のスキップ先」は最大セクション番号より小さい数字を選択してください。',
          lessSkipYesSectionNo: '［質問］%s行目の「「はい」を選択した場合のスキップ先」は現在のセクション番号より大きい数字を選択してください。',
          invalidSkipYesPattern: '［質問］%s行目の「「はい」を選択した場合のスキップ先」は「次のセクション」「質問終了」存在するセクション番号のいずれかを選択してください。',
          overSkipNoSectionNo: '［質問］%s行目の「「いいえ」を選択した場合のスキップ先」は最大セクション番号より小さい数字を選択してください。',
          lessSkipNoSectionNo: '［質問］%s行目の「「いいえ」を選択した場合のスキップ先」は現在のセクション番号より大きい数字を選択してください。',
          invalidSkipNoPattern: '［質問］%s行目の「「いいえ」を選択した場合のスキップ先」は「次のセクション」「質問終了」存在するセクション番号のいずれかを選択してください。',
          isNotNeedCondition: '［質問］%s行目の結果表示条件は選択不要です。',
          isNotConditionNullIfProcess: '［質問］%s行目の結果表示条件を選択してください。',
          isProcessNull: '［質問］%s行目の手続き対象を選択してください。',
          invalidProcessPattern: '［質問］%s行目の手続きが現在登録されているシステム上のデータと一致しません。修正方法については、<a href="https://www.faq.nicotto-admin.jp/faq/faq/index.html" target="_blank">よくある質問</a>を確認してください。',
          invalidAdditionalContactsPattern: '［質問］%s行目のその他の手続き可能な窓口%sが現在登録されているシステム上のデータと一致しません。修正方法については、<a href="https://www.faq.nicotto-admin.jp/faq/faq/index.html" target="_blank">よくある質問</a>を確認してください。',
          isLinkNameNull: '［質問］%s行目の関連リンク%sURLを入力した場合は関連リンク%s名称も入力してください。',
          invalidLinkUrlPattern: '［質問］%s行目の関連リンク%sURLの形式が正しくありません。',
          isLinkUrlNull: '［質問］%s行目の関連リンク%s名称を入力した場合は関連リンク%sURLも入力してください。',
          isApplicationNameNull: '［質問］%s行目の電子申請URLを入力した場合は電子申請名称も入力してください。',
          invalidApplicationUrlPattern: '［質問］%s行目の電子申請 URLの形式が正しくありません。',
          isApplicationUrlNull: '［質問］%s行目の電子申請名称を入力した場合は電子申請URLも入力してください。'
        }
      }
    },
    sections: {
      saveSucceeded: new SuccessMessage('セクションを登録しました。'),
      saveFailed: new ErrorMessage('セクションの登録に失敗しました。'),
      updateSucceeded: new SuccessMessage('セクションを更新しました。'),
      updateFailed: new ErrorMessage('セクションの更新に失敗しました。'),
    },
    questions: {
      saveSucceeded: new SuccessMessage('質問を登録しました。'),
      saveFailed: new ErrorMessage('質問の登録に失敗しました。'),
      updateSucceeded: new SuccessMessage('質問を更新しました。'),
      updateFailed: new ErrorMessage('質問の更新に失敗しました。'),
      deleteSucceeded: new SuccessMessage('質問を削除しました。'),
      deleteFailed: new ErrorMessage('質問の削除に失敗しました。'),
      isProcessNull: '手続き対象は必須です。',
      isConditionNull: '質問ありの場合、結果表示条件は必須です。',
    },
    drag: {
      saveSucceeded: new SuccessMessage('並び替え状態を保存しました。'),
      saveFailed: new ErrorMessage('並び替えの保存に失敗しました。もう一度確定ボタンを押してください。このままキャンセルすると、順番に不整合が起こります。', { dismiss: false }),
    },
    setting: {
      copyPreviewUrlFailed: new ErrorMessage('プレビューURLのコピーに失敗しました。'),
      updatePublishModeFailed: new ErrorMessage('公開状態の変更に失敗しました。もう一度やり直してください。'),
      updatePublishModeFailedByNoData: new ErrorMessage('公開対象のカテゴリがありません。'),
      updatePublishModeSucceeded: new SuccessMessage('公開状態を変更しました。'),
      updateSucceeded: new SuccessMessage('サイト設定を変更しました。'),
      updateFailed: new ErrorMessage('サイト設定の更新に失敗しました。'),
      modified: new WarningMessage('内容を変更した場合は、サイト公開前に必ず更新ボタンを押してください。', { dismiss: false })
    }
  },
  user: {
    noSearchResult: '該当ユーザはありません',
    getDataFailed: new ErrorMessage('ユーザ情報の取得に失敗しました。'),
    emailNotVerified: new ErrorMessage('メールアドレスの確認ができていません。メールに送付されている確認コードを入力してください', { to: '/account/confirm', dismiss: false }),
    updateSucceeded: new SuccessMessage('ユーザ情報を変更しました。'),
    updateFailed: new ErrorMessage('ユーザ情報の更新に失敗しました。'),
    saveFailedAlreadyExists: new ErrorMessage('この自治体名(URL用)はすでに登録されているため、使用できません。'),
  },
  application: {
    emailSendFailed: new ErrorMessage('メールアドレスの送付に失敗しました。再度メールアドレスを登録してください。'),
    sendFailed: new ErrorMessage('仮申込みに失敗しました。'),
  },
  code: {
    sendSucceeded: new SuccessMessage('確認コードを送信しました。'),
    sendFailed: new ErrorMessage('確認コードの送信に失敗しました。'),
    resendSucceeded: new SuccessMessage('確認コードを再送しました。'),
    resendFailed: new ErrorMessage('確認コードの再送に失敗しました。'),
    authenticationFailed: new ErrorMessage('確認コードの承認に失敗しました。'),
    mismatchException: new ErrorMessage('確認コードに誤りがあります。'),
    isExpired: new ErrorMessage('確認コードが期限切れです。確認コードを再送してください。'),
    enterEmail: new ErrorMessage('確認コードを送付するメールアドレスを入力してください。'),
  },
  forgot: {
    emailAuthError: new ErrorMessage('認証に失敗しました。'),
    retrySendCode: new ErrorMessage('認証に失敗しました。確認コードの再送からやり直してください。', { dismiss: false }),
    retryLater: new ErrorMessage('認証に失敗しました。しばらく時間が経ってからやり直してください。', { dismiss: false })
  },
  signIn: {
    incorrectIdOrPassword: 'IDまたはパスワードが間違っています。'
  }
}

/**
 * キーに一致するメッセージを探し、値を返す
 * @param key
 */
const findMessage = (key: string): string|Message => {
  const message = key.split('.').reduce((obj: any, k: string) => {
    if (obj) {
      return obj[k]
    }
  }, messages)
  if (typeof message == 'string' || message?.text && message?.variant) {
    return message
  }
  // メッセージが見つからない場合はキーを返す
  return key
}

/**
 * キーに一致するメッセージのテキストを返す
 * @param key
 */
export const message = (key: string): string => {
  const message = findMessage(key)
  return (typeof message == 'string') ? message : message.text
}

type Messenger = {
  set(key:string): void
  hide(): void
  get(): Message|null
  hasMessage(): boolean
  isShow(): boolean
}

/**
 * プラグイン
 */
export const injectKey: InjectionKey<Messenger> = Symbol('messenger')
export default {
  install: (app: App) => {
    const store = app.config.globalProperties.$store
    app.config.globalProperties.$messenger = {
      set(key: string) {
        store.commit('setMessage', findMessage(key))
      },
      hide() {
        store.commit('setShowAlert', false)
      },
      get() {
        return store.state.message
      },
      hasMessage() {
        return !!store.state.message
      },
      isShow() {
        return store.state.showAlert
      }
    }
    app.provide(injectKey, app.config.globalProperties.$messenger)
  }
}

// Composition API
export function useMessenger() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return inject(injectKey)!
}