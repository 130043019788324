
import { defineComponent, computed } from 'vue'
import InputText from '@/components/ui/InputText.vue'

export default defineComponent({
  components: { InputText },
  props: {
    required: { type: Boolean, default: false },
    modelValue: { type: String, default: '' },
    label: { type: String, default: 'URL' }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const url = computed({
      get: () => props.modelValue,
      set: (val: string) => (emit('update:modelValue', val))
    })

    return {
      url,
      pattern: 'https?://.*'
    }
  }
})
