import { format } from 'util'
import { Concierge } from '.'
import { CsvImporter } from '../utils'
import { message } from "@/plugins/message";

export class ConciergeDepartment {

  public static keyPrefix = 'concierge#department#'

  code: string
  name: string
  locations: string[]
  tel?: string
  url?: string
  note?: string
  orderSeq?: number

  constructor(data: any = {}) {
    // sortKeyから担当課コードを取得
    if (data.code == null && data.sortKey) {
      data.code = data.sortKey.replace(ConciergeDepartment.keyPrefix, '')
    }
    this.code = data.code || ''
    this.name = data.name || ''
    this.locations = data.locations || []
    if (this.locations.length == 0) {
      this.locations.push(data.location1||'')
      this.locations.push(data.location2||'')
    }
    this.tel = data.tel
    this.url = data.url
    if (data.orderSeq != null && data.orderSeq !== '') {
      const orderSeq = parseInt(data.orderSeq)
      this.orderSeq = isNaN(orderSeq) ? data.orderSeq : orderSeq
    }
    this.note = data.note
  }

  sortKey() {
    return `${ConciergeDepartment.keyPrefix}${this.code}`
  }

  displayName() {
    return [this.code, this.name].join('：')
  }

  flatten() {
    return {
      code: this.code,
      name: this.name,
      location1: this.locations.at(0),
      location2: this.locations.at(1),
      tel: this.tel,
      url: this.url,
      note: this.note,
      orderSeq: this.orderSeq
    }
  }
}

export const DepartmentCsvFields = {
  code: '担当課コード',
  name: '担当課名',
  location1: '所在地1',
  location2: '所在地2',
  tel: '電話番号',
  url: 'URL',
  orderSeq: '結果表示順',
  note: '備考'
} as const

export class DepartmentCsvImporter extends CsvImporter {
  protected validateFields(item: any, rowNo: number, errors: string[]) {
    if (item.code === '') {
      errors.push(format(message('concierge.departments.importValidation.isCodeNull'), rowNo))
    }
    if (!new RegExp(/^(?!0)[-_0-9A-Za-z]*$|^0(?=.*[-_A-Za-z])[-_0-9A-Za-z]*$/).test(item.code)) {
      errors.push(format(message('concierge.departments.importValidation.invalidCodePattern'), rowNo))
    }
    if (item.name === '') {
      errors.push(format(message('concierge.departments.importValidation.isNameNull'), rowNo))
    }
    if (item.tel !== '') {
      if (!new RegExp(/^[\d+]{1,6}-.+$/).test(item.tel)) {
        errors.push(format(message('concierge.departments.importValidation.invalidTelPattern'), rowNo))
      }
    }
    if (item.url !== '') {
      if (!new RegExp('https?://[\\w!?/+\\-_~;.,*&@#$%()\'[\\]]+').test(item.url)) {
        errors.push(format(message('concierge.departments.importValidation.invalidUrlPattern'), rowNo))
      }
    }
    if (item.orderSeq !== '') {
      if (!new RegExp(/^[0-9]+$/).test(item.orderSeq)) {
        errors.push(format(message('concierge.departments.importValidation.isNotNumber'), rowNo))
      }
    }
  }

  protected postValidate(errors: string[]): void {
    const codes = this.data.map(function(item:any) {
      return item.code;
    });
    const duplicate = codes.filter((val:string, index:number) => codes.indexOf(val) < index )
    const unique = [...new Set(duplicate)]
    if (unique.length) {
      errors.push(format(message('concierge.departments.importValidation.isDuplicated'), unique.toString()))
    }
  }

  async import() {
    const items = this.diff(await Concierge.getDepartmentList(), this.data)
    await Concierge.batchImportDepartment(items)
  }

  private diff(items: any[], newItems: any[]) {
    // 削除対象を検出するため、全項目の削除フラグを立てておく
    const itemsMap = new Map(items.map(item => {
      item.deleted = true
      return [item.code, item]
    }))

    // インポートデータに存在する場合はデータを上書き
    newItems.forEach(newItem => {
      itemsMap.set(newItem.code, { ...new ConciergeDepartment(newItem), deleted: false })
    })
    return [...itemsMap.values()]
  }
}