import { vModelText as _vModelText, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["required", "rows"]
const _hoisted_2 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("textarea", _mergeProps({ class: "form-control" }, _ctx.$attrs, {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textArea) = $event)),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validate && _ctx.validate(...args))),
      required: _ctx.required,
      rows: _ctx.rows
    }), null, 16, _hoisted_1), [
      [_vModelText, _ctx.textArea]
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.error), 1)
  ], 64))
}