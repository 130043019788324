
import { defineComponent } from 'vue'
import Editor from '@tinymce/tinymce-vue'

export default defineComponent({
  components: { Editor },
  props: {
    modelValue: { type: String, default: '' }
  },
  emits: ['update:modelValue'],
  computed: {
    content: {
      get(): string {
        return this.modelValue
      },
      set(val: string): void {
        this.$emit('update:modelValue', val)
      }
    },
    init() {
      // Ref. https://www.tiny.cloud/docs/configure/content-formatting/
      return {
        language: 'ja',
        menubar: false,
        branding: false,
        contextmenu: false,
        height: 300,
        plugins: 'link lists table',
        toolbar: 'undo redo | bold italic underline strikethrough forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent h2 h3 h4 | link table',
        block_formats: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6;',
        default_link_target: '_blank',
        setup: (editor: any) => {
          editor.on('change blur', () => {
            this.content = editor.getContent()
          })
        }
      }
    }
  }
})
