import { format } from 'util'
import { v4 as uuidv4 } from 'uuid'
import { Concierge } from '.'
import { ConciergeLink } from '../types'
import { CsvImporter } from '../utils'
import { ConciergeDepartment } from './department'
import { message } from '@/plugins/message'

export class ConciergeProcess {

  public static keyPrefix = 'concierge#process#'

  processId: string
  name: string
  department: ConciergeDepartment
  link: ConciergeLink
  orderBy?: string
  readonly refId: string|null

  constructor(data: any = {}) {
    // sortKeyから手続きIDを取得
    if (data.processId == null && data.sortKey) {
      data.processId = data.sortKey.replace(ConciergeProcess.keyPrefix, '')
    }
    this.processId = data.processId || uuidv4()
    this.name = data.name || ''
    this.link = data.link || { name: '', url: '' }
    if (!data.link && data.linkName && data.linkUrl) {
      this.link = { name: data.linkName, url: data.linkUrl }
    }
    this.department = data.department || new ConciergeDepartment()
    this.orderBy = data.orderBy
    this.refId = data.refId
  }

  static type(municipalityId: string) {
    return `ConciergeProcess#${municipalityId}`
  }

  sortKey() {
    return `${ConciergeProcess.keyPrefix}${this.processId}`
  }

  flatten() {
    return {
      processId: this.processId,
      name: this.name,
      linkName: this.link.name,
      linkUrl: this.link.url,
      departmentCode: this.department.code,
      departmentName: this.department.name,
      departmentDisplayName: this.department.displayName(),
    }
  }

  toInput() {
    return {
      processId: this.processId,
      name: this.name,
      link: this.link,
      deptCode: this.department.code,
      refId: this.refId,
      orderBy: this.orderBy == null ? '99' + Date.now().toString() : this.orderBy?.padStart(3, '0')
    }
  }
}

export const ProcessCsvFields = {
  name: '手続き',
  departmentDisplayName: '担当課',
  linkName: '関連リンク名',
  linkUrl: '関連リンクURL',
} as const

export class ProcessCsvImporter extends CsvImporter {
  private departments: ConciergeDepartment[] = []

  setDepartments(departments: ConciergeDepartment[]) {
    this.departments = departments
  }

  protected validateFields(item: any, rowNo: number, errors: string[]) {
    if (item.name === '') {
      errors.push(format(message('concierge.processes.importValidation.isProcessNull'), rowNo))
    }
    if (item.departmentDisplayName === '') {
      errors.push(format(message('concierge.processes.importValidation.isDepartmentNull'), rowNo))
    } else if (this.departments.find(d => d.displayName() === item.departmentDisplayName) == null) {
      errors.push(format(message('concierge.processes.importValidation.invalidDepartmentPattern'), rowNo))
    }
    if (item.linkName !== '' && item.linkUrl === '') {
      errors.push(format(message('concierge.processes.importValidation.isLinkUrlNull'), rowNo))
    }
    if (item.linkName === '' && item.linkUrl !== '') {
      errors.push(format(message('concierge.processes.importValidation.isLinkNameNull'), rowNo))
    }
    if (item.linkUrl !== '') {
      if (!new RegExp('https?://[\\w!?/+\\-_~;.,*&@#$%()\'[\\]]+').test(item.linkUrl)) {
        errors.push(format(message('concierge.processes.importValidation.invalidLinkUrlPattern'), rowNo))
      }
    }
  }

  protected postValidate(errors: string[]): void {
    const getProcessNameList = this.data.map((obj) => obj.name)
    const duplicate = getProcessNameList.filter((val:string, index:number) => getProcessNameList.indexOf(val) < index )
    const unique = [...new Set(duplicate)]
    if (unique.length) {
      errors.push(format(message('concierge.processes.importValidation.isDuplicated'), unique.toString()))
    }
  }

  async import() {
    const items = this.diff(await Concierge.getProcessList(true), this.data)
    await Concierge.batchImportProcess(items)
  }

  private diff(items: ConciergeProcess[], newItems: any[]): any[] {
    // 削除対象を検出するため、全項目の削除フラグを立てておく
    const itemsMap = new Map(items.map(item => {
      return [item.name, { ...item.toInput(), deleted: true }]
    }))

    // インポートデータに存在する場合はデータを上書き
    newItems.forEach((newItem, index) => {
      const oldItem = itemsMap.get(newItem.name)
      if (oldItem) {
        newItem.processId = oldItem.processId
      }
      const process = new ConciergeProcess(newItem)
      process.orderBy = (index + 1).toString()
      itemsMap.set(newItem.name, {
        ...process.toInput(),
        deptCode: newItem.departmentDisplayName.split('：')[0],
        deleted: false
      })
    })
    return [...itemsMap.values()]
  }
}
