import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor = _resolveComponent("editor")!

  return (_openBlock(), _createBlock(_component_editor, {
    init: _ctx.init,
    modelValue: _ctx.content,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.content) = $event)),
    "tinymce-script-src": "/tinymce/tinymce.min.js"
  }, null, 8, ["init", "modelValue"]))
}