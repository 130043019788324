import { format } from 'util'
import { v4 as uuidv4 } from 'uuid'
import { ConciergeDepartment } from './department'
import { ConciergeProcess } from './process'
import { ConciergeLink, ConciergeProcessConditionType } from "../types"
import xlsx from 'xlsx-populate/browser/xlsx-populate'
import { ConciergeCategoryInput, CreateMunicipalityInput, UpdateMunicipalityInput } from '../types/api'
import { Concierge } from '.'
import { message } from '@/plugins/message'

// Excelのシート定義
export const CategoryExcelSheets = {
  question: '質問',
  process: '手続きマスタ',
  department: '担当課マスタ'
} as const
export type CategoryExcelSheetName = typeof CategoryExcelSheets[keyof typeof CategoryExcelSheets]

// Excelテンプレート
export async function getCategoryExcelTemplate() {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const dataUrl = require('@/assets/concierge/category_template.xlsx')
  return (await fetch(dataUrl)).blob()
}

export class ConciergeCategory {

  public static keyPrefix = 'concierge#question#'
  public static iconWidth = 150

  categoryId: string
  pageId: string
  name: string
  description: string|null
  lead: string|null
  iconUrl: string|null
  iconPath: string|null
  links: ConciergeLink[]
  publishMode: string|null
  displayCommonBelongings: string|null
  belongings: string|null
  startButtonName: string|null
  orderBy: string|null
  readonly refId: string|null

  constructor(data: any = {}) {
    // sortKeyからカテゴリIDを取得
    if (data.categoryId == null && data.sortKey) {
      data.categoryId = data.sortKey.replace(ConciergeCategory.keyPrefix, '')
    }
    this.categoryId = data.categoryId || uuidv4()
    this.pageId = data.pageId || ''
    this.name = data.name || ''
    this.description = data.description
    this.lead = data.lead
    this.iconUrl = data.iconUrl
    this.iconPath = data.iconPath
    this.links = data.links || []
    this.publishMode = data.publishMode || ''
    this.displayCommonBelongings = data.displayCommonBelongings || ''
    this.belongings = data.belongings || ''
    this.startButtonName = data.startButtonName || ''
    this.orderBy = data.orderBy
    this.refId = data.refId
  }

  static type(municipalityId: string) {
    return `ConciergeCategory#${municipalityId}`
  }

  sortKey() {
    return `${ConciergeCategory.keyPrefix}${this.categoryId}`
  }

  toInput(): ConciergeCategoryInput {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { iconUrl, ...input } = this
    input.orderBy = this.orderBy == null ? '99' + Date.now().toString() : this.orderBy?.padStart(3, '0')
    return input
  }
}

interface IConciergeQuestion {
  name: string
  question?: string|null
  supplement?: string|null
  skip?: { yes?: string, no?: string }|null
}

const toQuestionInputAttrs = (obj: IConciergeQuestion) => {
  const attrs: IConciergeQuestion = { name: obj.name }
  if (obj.question && obj.question !== '') {
    attrs.question = obj.question
    attrs.supplement = obj.supplement
    if (obj.skip) {
      attrs.skip = { yes: obj.skip.yes ?? '', no: obj.skip.no ?? '' }
    }
  } else if (obj.question === '') {
    attrs.question = null
    attrs.supplement = null
    attrs.skip = null
  }
  return attrs
}

export class ConciergeSection {

  categoryId: string
  sectionId: string
  name: string
  question?: string
  supplement?: string
  skip?: { yes?: string, no?: string } = {}

  constructor(data: any = {}) {
    if (data.sortKey) {
      const ids = data.sortKey.replace(ConciergeCategory.keyPrefix, '').split('#')
      if (data.categoryId == null) {
        data.categoryId = ids[0]
      }
      if (data.sectionId == null) {
        data.sectionId = ids[1]
      }
    }
    this.categoryId = data.categoryId || ''
    this.sectionId = data.sectionId || ''
    this.name = data.name || ''
    if (data.question != null) {
      this.question = data.question
      this.supplement = data.supplement
      this.skip = data.skip || {}
    }
  }

  static keyPrefix(categoryId: string) {
    return `${ConciergeCategory.keyPrefix}${categoryId}#`
  }

  static type(municipalityId: string, categoryId: string) {
    return `ConciergeSection#${municipalityId}#${categoryId}`
  }

  sortKey() {
    return `${ConciergeSection.keyPrefix(this.categoryId)}${this.sectionId}`
  }

  // APIの入力形式に変換
  toInput(municipalityId: string) {
    const input: CreateMunicipalityInput|UpdateMunicipalityInput = {
      id: municipalityId,
      sortKey: this.sortKey(),
      type: ConciergeSection.type(municipalityId, this.categoryId),
      orderBy: this.sectionId.padStart(3, '0'),
      ...toQuestionInputAttrs(this)
    }
    return input
  }
}

export class ConciergeQuestion {
  categoryId: string
  sectionId: string
  questionId: string
  name: string
  question?: string
  supplement?: string
  skip?: { yes?: string, no?: string } = {}
  processes: ConciergeQuestionProcess[]
  orderBy?: string

  constructor(data: any = {}) {
    if (data.sortKey) {
      const ids = data.sortKey.replace(ConciergeCategory.keyPrefix, '').split('#')
      if (data.categoryId == null) {
        data.categoryId = ids[0]
      }
      if (data.sectionId == null) {
        data.sectionId = ids[1]
      }
      if (data.questionId == null) {
        data.questionId = ids[2]
      }
    }
    this.categoryId = data.categoryId || ''
    this.sectionId = data.sectionId || ''
    this.questionId = data.questionId || uuidv4()
    this.name = data.name || ''
    if (data.question != null) {
      this.question = data.question
      this.supplement = data.supplement
      this.skip = data.skip || {}
    }
    this.processes = data.processes || [new ConciergeQuestionProcess()]
    this.orderBy = data.orderBy
  }

  static keyPrefix(categoryId: string, sectionId: string) {
    return `${ConciergeCategory.keyPrefix}${categoryId}#${sectionId}#`
  }

  static type(municipalityId: string, categoryId: string, sectionId: string) {
    return `ConciergeQuestion#${municipalityId}#${categoryId}#${sectionId}`
  }

  sortKey() {
    return ConciergeQuestion.keyPrefix(this.categoryId, this.sectionId) + this.questionId
  }

  toInput(municipalityId: string) {
    const input: CreateMunicipalityInput|UpdateMunicipalityInput = {
      id: municipalityId,
      sortKey: this.sortKey(),
      type: ConciergeQuestion.type(municipalityId, this.categoryId, this.sectionId),
      orderBy: this.orderBy == null ? '99' + Date.now().toString() : this.orderBy?.padStart(3, '0'),
      ...toQuestionInputAttrs(this),
      processes: this.processes.map(process => process.toInput())
    }
    return input
  }
}

export class ConciergeQuestionProcess {
  static readonly maxLinkLength = 3
  static readonly maxAdditionalContactLength = 3

  condition: ConciergeProcessConditionType|null
  processId: string
  process?: {
    id: string
    name: string
  }
  name: string
  belongings?: string
  additionalContacts: string[]
  supplement?: string
  links: ConciergeLink[]
  eApplication?: {
    link?: ConciergeLink
    supplement?: string
  }
  note?: string

  constructor(data: any = {}) {
    this.condition = data.condition
    this.processId = data.processId || ''
    if (data.process) {
      this.process = data.process
    }
    this.name = data.name || ''
    this.belongings = data.belongings
    this.additionalContacts = data.additionalContacts || []
    this.supplement = data.supplement
    this.links = data.links || []
    this.eApplication = data.eApplication || {}
    if (this.eApplication && !this.eApplication.link) {
      this.eApplication.link = { name: '', url: '' }
    }
    this.note = data.note
  }

  toInput() {
    return {
      condition: this.condition,
      processId: this.processId,
      name: this.name,
      belongings: this.belongings,
      additionalContacts: this.additionalContacts,
      supplement: this.supplement,
      links: this.links.filter(link => link.url),
      eApplication: {
        link: this.eApplication?.link?.url ? this.eApplication.link : null,
        supplement: this.eApplication?.supplement
      },
      note: this.note
    }
  }
}

export class CategoryExcel {

  static readonly startDataRow = 3

  constructor(private workbook: Workbook) {}

  static async createFromData(data: ArrayBuffer|Uint8Array|Buffer|Blob) {
    return new CategoryExcel(await xlsx.fromDataAsync(data))
  }

  async toBlob() {
    this.workbook.activeSheet(CategoryExcelSheets.question)
    return await this.workbook.outputAsync('blob') as Blob
  }

  getSheet(name: CategoryExcelSheetName) {
    return this.workbook.sheet(name)
  }

  /**
   * 指定シートの使用範囲のデータを返す
   * 
   * @param name シート名
   * @param colNames 列名定義
   * @param skipEmpty 空行を飛ばすかどうか
   * @returns 使用範囲(行の配列)
   */
  getSheetData(
    name: CategoryExcelSheetName,
    colNames: string[],
    skipEmpty = true
  ): Array<{[key: string]: Cell}> {
    const sheet = this.getSheet(name)
    if (! sheet) {
      return []
    }
    let rows = sheet.usedRange()?.cells() || []
    if (skipEmpty) {
      rows = rows.filter((row: Cell[]) => {
        return row.filter(cell => cell.value() != null).length > 0
      })
    }
    // 各行の値をCellの配列から、列名をキーとしたObjectに変換
    return rows.map((row: Cell[]) => {
      return Object.assign({}, ...colNames.map((name, i) => {
        const cell = row.at(i)
        // RichTextの場合、書式を取り除いてテキストのみをセルに設定
        if (cell?.value() && typeof cell.value() === 'object'
          && typeof (cell.value() as RichText).text === 'function') {
          cell.value((cell.value() as RichText).text())
        }
        return { [name]: cell }
      }))
    })
  }

  /**
   * 質問シートのレンダリング
   * 
   * @param sections セクション一覧
   * @param departments 担当課一覧
   * @param getQuestionsCallback セクションの質問一覧を取得するコールバック
   */
  async renderQuestionSheet(
    sections: ConciergeSection[],
    departments: ConciergeDepartment[],
    getQuestionsCallback: (sectionId: string) => Promise<ConciergeQuestion[]>
  ) {
    const sheet = this.getSheet(CategoryExcelSheets.question) as Sheet
    const departmentMap = new Map(departments.map(d => [d.code, d.displayName()]))

    let rowNo = CategoryExcel.startDataRow
    let sectionNo = 1
    for (const section of sections) {
      sheet.cell(`A${rowNo}`).value(sectionNo)
      sheet.cell(`B${rowNo}`).value(section.name)
      sheet.cell(`D${rowNo}`).value(section.question)
      sheet.cell(`E${rowNo}`).value(section.supplement)
      if (!section.question) {
        sheet.cell(`C${rowNo}`).value('質問なし')
      }
      if (section.skip) {
        if (section.skip.yes) {
          sheet.cell(`F${rowNo}`).value(this.getSkip(section.skip.yes))
        }
        if (section.skip.no) {
          sheet.cell(`G${rowNo}`).value(this.getSkip(section.skip.no))
        }
      }

      sectionNo++
      rowNo++

      const questions = await getQuestionsCallback(section.sectionId)
      for (const question of questions) {
        sheet.cell(`B${rowNo}`).value(question.name)
        sheet.cell(`D${rowNo}`).value(question.question)
        sheet.cell(`E${rowNo}`).value(question.supplement)
        if (!question.question) {
          sheet.cell(`C${rowNo}`).value('質問なし')
        }
        if (question.skip) {
          if (question.skip.yes) {
            sheet.cell(`F${rowNo}`).value(this.getSkip(question.skip.yes))
          }
          if (question.skip.no) {
            sheet.cell(`G${rowNo}`).value(this.getSkip(question.skip.no))
          }
        }
        let repeat = 0

        if (question.processes.length) {
          for (const process of question.processes) {
            if (repeat === 1) {
              sheet.cell(`B${rowNo}`).value(question.name)
              sheet.cell(`C${rowNo}`).value('上と同じ')
            }
            const condition = this.getCondition(process.condition)
            sheet.cell(`H${rowNo}`).value(condition)
            sheet.cell(`I${rowNo}`).value(process.process?.name)
            sheet.cell(`J${rowNo}`).value(process.name)
            sheet.cell(`K${rowNo}`).value(process.belongings)
            if (process.additionalContacts) {
              sheet.cell(`M${rowNo}`).value(departmentMap.get(process.additionalContacts[0]))
              sheet.cell(`N${rowNo}`).value(departmentMap.get(process.additionalContacts[1]))
              sheet.cell(`O${rowNo}`).value(departmentMap.get(process.additionalContacts[2]))
            }
            sheet.cell(`P${rowNo}`).value(process.supplement)
            sheet.cell(`Q${rowNo}`).value(process.note)
            if (process.links && process.links[0]) {
              sheet.cell(`R${rowNo}`).value(process.links[0].name)
              sheet.cell(`S${rowNo}`).value(process.links[0].url)
            }
            if (process.links && process.links[1]) {
              sheet.cell(`T${rowNo}`).value(process.links[1].name)
              sheet.cell(`U${rowNo}`).value(process.links[1].url)
            }
            if (process.links && process.links[2]) {
              sheet.cell(`V${rowNo}`).value(process.links[2].name)
              sheet.cell(`W${rowNo}`).value(process.links[2].url)
            }
            if (process.eApplication && process.eApplication.link) {
              sheet.cell(`X${rowNo}`).value(process.eApplication.link.name)
              sheet.cell(`Y${rowNo}`).value(process.eApplication.link.url)
            }
            if (process.eApplication && process.eApplication.supplement) {
              sheet.cell(`Z${rowNo}`).value(process.eApplication.supplement)
            }
            repeat = 1
            rowNo++
          }
        } else {
          rowNo++
        }
      }
    }
    sheet.cell('A1').active(true)
  }

  private getSkip(val: string) {
    if (val === "next") {
      return '次のセクション'
    } else if (val === "end") {
      return '質問終了'
    } else {
      return val
    }
  }

  private getCondition(type: ConciergeProcessConditionType|null) {
    if (type === "yes") {
      return 'はい'
    } else if (type === "no") {
      return 'いいえ'
    } else {
      return ''
    }
  }

  renderProcessSheet(processes: ConciergeProcess[]) {
    const sheet = this.getSheet(CategoryExcelSheets.process) as Sheet

    let rowNo = 2
    processes.forEach((process) => {
      sheet.cell(`A${rowNo}`).value(process.name)
      sheet.cell(`B${rowNo}`).value(`${process.department.code}：${process.department.name}`)
      rowNo++
    })

    sheet.hidden(true)
    sheet.cell('A1').active(true)
  }

  renderDepartmentSheet(departments: ConciergeDepartment[]) {
    const sheet = this.getSheet(CategoryExcelSheets.department) as Sheet

    let rowNo = 2
    departments.forEach((department) => {
      //sheet.cell(`A${rowNo}`).formula(format('=B%s&IF(B%s<>"","：","")&C%s', rowNo))
      sheet.cell(`B${rowNo}`).value(department.code)
      sheet.cell(`C${rowNo}`).value(department.name)
      rowNo++
    })

    sheet.hidden(true)
    sheet.cell('A1').active(true)
  }
}

export class CategoryExcelImporter {

  private questionRows: Array<{[key: string]: Cell}> = []
  private processnRows: Array<{[key: string]: Cell}> = []
  private departmentRows: Array<{[key: string]: Cell}> = []
  private processMap: Map<string, ConciergeProcess>
  private departmentMap: Map<string, ConciergeDepartment>
  private errors: string[] = []

  constructor(
    private category: ConciergeCategory,
    private excel: CategoryExcel,
    processes: ConciergeProcess[],
    departments: ConciergeDepartment[]
  ) {
    // 各シートの行定義
    this.questionRows = this.excel.getSheetData(
      CategoryExcelSheets.question,
      ['sectionNo', 'name', 'questionType', 'question', 'supplement', 'skipYes', 'skipNo', 'condition', 'process', 'processType', 'belongings', 'representativeContact', 'additionalContacts0', 'additionalContacts1', 'additionalContacts2', 'additionalSupplement', 'note', 'linkName0', 'linkUrl0', 'linkName1', 'linkUrl1', 'linkName2', 'linkUrl2', 'applicationName', 'applicationUrl', 'applicationSupplement']
    )
    this.processnRows = this.excel.getSheetData(
      CategoryExcelSheets.process,
      ['name', 'department']
    )
    this.departmentRows = this.excel.getSheetData(
      CategoryExcelSheets.department,
      ['displayName', 'code', 'name']
    )

    // マスタ
    this.processMap = new Map(processes.map(p => [p.name, p]))
    this.departmentMap = new Map(departments.map(d => [d.displayName(), d]))
  }

  isValid() {
    let beforeRow = {}
    let beforeSectionRow = {}
    const maxSection = this.maxSection()
    this.questionRows.forEach(row => {
      // データ行をチェック
      if (row.sectionNo.rowNumber() >= CategoryExcel.startDataRow) {
        if (this.isSectionRow(row)) {
          this.validateSection(row, row.sectionNo.rowNumber(), beforeRow, beforeSectionRow, maxSection)
          beforeSectionRow = row
        } else {
          this.validateQuestion(row, row.sectionNo.rowNumber(), beforeRow, beforeSectionRow, maxSection)
        }
        beforeRow = row
      }
    })

    this.processnRows.forEach(row => {
      this.validateProcess(row, row.name.rowNumber())
    })

    this.departmentRows.forEach(row => {
      this.validateDepartment(row, row.code.rowNumber())
    })

    return this.errors.length == 0
  }

  getErrors() {
    return this.errors
  }

  async import(): Promise<void> {
    // 質問シートからデータ構築
    const newSections: ConciergeSection[] = []
    const newQuestions: { [key: string]: ConciergeQuestion[] } = {}
    let sectionId: string
    let question: ConciergeQuestion
    this.questionRows.forEach(row => {
      if (row.sectionNo.rowNumber() >= CategoryExcel.startDataRow) {
        if (this.isSectionRow(row)) {
          const section = this.toObject(row) as ConciergeSection
          sectionId = section.sectionId
          newSections.push(section)
          newQuestions[sectionId] = []
        } else if (this.isProcessRow(row)) {
          question.processes.push(this.toObject(row) as ConciergeQuestionProcess)
        } else {
          question = this.toObject(row) as ConciergeQuestion
          question.sectionId = sectionId
          question.orderBy = String(newQuestions[sectionId].length + 1)
          newQuestions[sectionId].push(question)
        }
      }
    })

    // 既存のセクションと質問
    const currentSections: ConciergeSection[] = []
    const currentQuestions: { [key: string]: ConciergeQuestion[] } = {}
    const items = await Concierge.getList({ beginsWith: ConciergeSection.keyPrefix(this.category.categoryId) })
    const sectionType = ConciergeSection.type(Concierge.municipalityId, this.category.categoryId)
    items.forEach(item => {
      if (item.type == sectionType) {
        const section = new ConciergeSection(item)
        currentSections.push(section)
        currentQuestions[section.sectionId] = []
      } else {
        const question = new ConciergeQuestion(item)
        if (!currentQuestions[question.sectionId]) {
          currentQuestions[question.sectionId] = []
        }
        currentQuestions[question.sectionId].push(question)
      }
    })

    // 既存データと新データの差分
    const sections = this.diffSection(currentSections, newSections)
    const questions = []
    for (const section of sections) {
      questions.push(...this.diffQuestion(
        currentQuestions[section.sectionId] || [],
        newQuestions[section.sectionId] || []
      ))
    }

    // 永続化
    await Concierge.batchImportConciergeQuestion([...sections, ...questions])
  }

  private toObject(row: {[key: string]: Cell}) {
    const skipYes = row.skipYes.value()
    const skipNo = row.skipNo.value()
    const item: any = {
      categoryId: this.category.categoryId,
      name: row.name.value(),
      question: (row.questionType.value() == null) ? row.question.value() : null,
      skip: {
        yes: skipYes ? this.getSkipVal(String(skipYes)) : null,
        no: skipNo ? this.getSkipVal(String(skipNo)) : null
      },
      supplement: row.supplement.value()
    }

    // セクション
    if (this.isSectionRow(row)) {
      item.sectionId = row.sectionNo.value()?.toString()
      return new ConciergeSection(item)
    // 手続き
    } else if (this.isProcessRow(row)) {
      return this.createProcess(row)
    // 質問
    } else {
      item.processes = row.process.value() ? [this.createProcess(row)] : []
      return new ConciergeQuestion(item)
    }
  }

  private createProcess(row: {[key: string]: Cell}) {
    const conditions: any = { 'はい': 'yes', 'いいえ': 'no' }

    // 関連リンク
    const links = []
    for (let i = 0; i < ConciergeQuestionProcess.maxLinkLength; i++) {
      const url = row['linkUrl' + i].value()
      if (url) {
        links.push({ name: row['linkName' + i].value(), url: url })
      }
    }
    // 追加窓口
    const contacts = []
    for (let i = 0; i < ConciergeQuestionProcess.maxAdditionalContactLength; i++) {
      const department = this.departmentMap.get(row['additionalContacts' + i].value() as string)
      if (department) {
        contacts.push(department.code)
      }
    }

    return new ConciergeQuestionProcess({
      condition: conditions[row.condition.value() as string],
      name: row.processType.value(),
      processId: this.processMap.get(row.process.value() as string)?.processId,
      belongings: row.belongings.value(),
      additionalContacts: contacts.length > 0 ? contacts : null,
      supplement: row.additionalSupplement.value(),
      note: row.note.value(),
      links: links.length > 0 ? links : null,
      eApplication: {
        link: row.applicationUrl.value()
          ? { name: row.applicationName.value(), url: row.applicationUrl.value() } : null,
        supplement: row.applicationSupplement.value()
      }
    })
  }

  private diffSection(items: ConciergeSection[], newItems: ConciergeSection[]) {
    // 削除対象を検出するため、全項目の削除フラグを立てておく
    const itemsMap = new Map(items.map(item => {
      return [item.sectionId, { item, sectionId: item.sectionId, deleted: true }]
    }))

    // インポートデータに存在する場合はデータを上書き
    newItems.forEach(newItem => {
      itemsMap.set(newItem.sectionId, { item: newItem, sectionId: newItem.sectionId, deleted: false })
    })
    return [...itemsMap.values()]
  }

  private diffQuestion(items: ConciergeQuestion[], newItems: ConciergeQuestion[]) {
    // 削除対象を検出するため、全項目の削除フラグを立てておく
    const itemsMap = new Map(items.map((item, index) => {
      return [index, { item, deleted: true }]
    }))

    // インポートデータに存在する場合はデータを上書き
    newItems.forEach((newItem, index) => {
      const item = itemsMap.get(index)
      if (item) {
        newItem.questionId = item.item.questionId
      }
      itemsMap.set(index, { item: newItem, deleted: false })
    })
    return [...itemsMap.values()]
  }

  private isSectionRow(row: {[key: string]: Cell}) {
    return !!row.sectionNo.value()
  }

  private isProcessRow(row: {[key: string]: Cell}) {
    return row.questionType.value() === "上と同じ"
  }

  private validateSection(row: {[key: string]: Cell}, rowNo: number, beforeRow: {[key: string]: Cell}, beforeSectionRow: {[key: string]: Cell}, maxSectionNo: number) {
    const rowSectionNo = row.sectionNo.value()
    if (Object.keys(beforeRow).length) {
      if (this.isSectionRow(beforeRow)) {
        this.errors.push(format(message('concierge.categories.importValidation.sections.isEmpty'), rowNo))
      }
    }
    // セクション番号の型チェック
    if (typeof rowSectionNo != "number") {
      this.errors.push(format(message('concierge.categories.importValidation.sections.invalidSectionNoPattern'), rowNo))
    }

    // セクション連番チェック
    if (Object.keys(beforeSectionRow).length) {
      const beforeSectionNo = beforeSectionRow.sectionNo.value()
      if (typeof beforeSectionNo == "number" && typeof rowSectionNo == "number") {
        if (beforeSectionNo+1 != rowSectionNo) {
          this.errors.push(format(message('concierge.categories.importValidation.sections.sequenceError'), rowNo))
        }
      }
    // 先頭のセクション行
    } else {
      // 1から始まっているか
      if (rowSectionNo != 1) {
        this.errors.push(format(message('concierge.categories.importValidation.sections.startNoError'), rowNo))
      }
      // 3行目(先頭行)から始まっているか
      if (rowNo != 3) {
        this.errors.push(format(message('concierge.categories.importValidation.sections.startRowError'), rowNo))
      }
    }
    // セクション行は種別は必須
    if (typeof row.name.value() === "undefined") {
      this.errors.push(format(message('concierge.categories.importValidation.sections.isNameNull'), rowNo))
    }
    // 回答後のスキップ先で選択した数字が存在しないセクションの場合エラー
    this.checkSkipSection(row, rowNo, maxSectionNo, beforeSectionRow)
  }

  private validateQuestion(row: {[key: string]: Cell}, rowNo: number, beforeRow: {[key: string]: Cell}, beforeSectionRow: {[key: string]: Cell}, maxSectionNo: number) {
    if (row.questionType.value() == null) {
      if (row.question.value() == null) {
        this.errors.push(format(message('concierge.categories.importValidation.questions.isNull'), rowNo))
      }
      // 質問タイプが空欄の場合、結果表示条件と手続きのどちらか一方しか選択されていない場合エラー
      if (row.process.value() == null && row.condition.value() != null) {
        this.errors.push(format(message('concierge.categories.importValidation.questions.isProcessNull'), rowNo))
      } else if (row.process.value() != null && row.condition.value() == null) {
        this.errors.push(format(message('concierge.categories.importValidation.questions.isNotConditionNullIfProcess'), rowNo))
      }
    } else if (this.isProcessRow(row)) {
      if (Object.keys(beforeRow).length) {
        //上の行がセクション行の時
        if (beforeRow.sectionNo.value()) {
          this.errors.push(format(message('concierge.categories.importValidation.questions.isCopiedSection'), rowNo))
        } else {
          if (beforeRow && beforeRow.questionType.value() == "質問なし") {
            this.errors.push(format(message('concierge.categories.importValidation.questions.isCopiedNull'), rowNo))
          }
        }
      } else {
        //先頭行の時
        this.errors.push(format(message('concierge.categories.importValidation.questions.isCopiedNoRow'), rowNo))
      }
      // 手続き欄のときは結果表示条件は必須
      if (!row.condition.value()) {
        this.errors.push(format(message('concierge.categories.importValidation.questions.isNotConditionNullIfProcess'), rowNo))
      }
      // 手続き欄のときは手続き対象は必須
      if (!row.process.value()) {
        this.errors.push(format(message('concierge.categories.importValidation.questions.isProcessNull'), rowNo))
      }
    }
    // 質問タイプが「質問なし」の場合、結果表示条件は空欄以外の場合エラー
    if (row.questionType.value() === "質問なし" && row.condition.value() != null) {
      this.errors.push(format(message('concierge.categories.importValidation.questions.isNotNeedCondition'), rowNo))
    }

    // 回答後のスキップ先で選択した数字が存在しないセクションの場合エラー
    this.checkSkipSection(row, rowNo, maxSectionNo, beforeSectionRow)

    //・手続き対象
    if (row.process.value() != null) {
      //・手続き対象が手続きマスタに存在しない場合エラー
      const process = this.processMap.get(row.process.value() as string)
      if (!process) {
        this.errors.push(format(message('concierge.categories.importValidation.questions.invalidProcessPattern'), rowNo))
      }
    }

    //・その他の手続可能な窓口①～③がマスタに存在しない窓口の場合エラー
    const markIndex = ['①','②','③']

    for (let i = 0; i < ConciergeQuestionProcess.maxAdditionalContactLength; i++) {
      const additionalContacts = `additionalContacts${i}`
      if (row[additionalContacts] && row[additionalContacts].value()) {
        const department = this.departmentMap.get(row[additionalContacts].value() as string)
        if (!department) {
          this.errors.push(format(message('concierge.categories.importValidation.questions.invalidAdditionalContactsPattern'), rowNo, markIndex[i]))
        }
      }
    }

    //・関連リンク①～③URLがhttps://から始まらない時エラー
    const urlValidateCheck = (url:string|undefined) => {
      let result = true
      if (typeof url == "string") {
        if (!new RegExp('https?://[\\w!?/+\\-_~;.,*&@#$%()\'[\\]]+').test(url)) {
          result = false
        }
      }
      return result
    }

    for (let i = 0; i < ConciergeQuestionProcess.maxLinkLength; i++) {
      const linkName = `linkName${i}`
      const linkUrl = `linkUrl${i}`
      if (row[linkUrl].value()) {
        if (row[linkName].value() == null) {
          this.errors.push(format(message('concierge.categories.importValidation.questions.isLinkNameNull'), rowNo, markIndex[i], markIndex[i]))
        }
        const url = row[linkUrl].value()
        if (url != null && typeof url =="string") {
          if (!(urlValidateCheck(url))) {
            this.errors.push(format(message('concierge.categories.importValidation.questions.invalidLinkUrlPattern'), rowNo, markIndex[i]))
          }
        }
      } else {
        if (row[linkName].value()) {
          this.errors.push(format(message('concierge.categories.importValidation.questions.isLinkUrlNull'), rowNo, markIndex[i], markIndex[i]))
        }
      }
    }

    //電子申請URLがhttps://から始まらない時エラー
    const applicationUrl = row.applicationUrl.value()
    if (applicationUrl != null) {
      if (row.applicationName.value() == null) {
        this.errors.push(format(message('concierge.categories.importValidation.questions.isApplicationNameNull'), rowNo))
      }
      if (typeof applicationUrl =="string") {
        if (!(urlValidateCheck(applicationUrl))) {
          this.errors.push(format(message('concierge.categories.importValidation.questions.invalidApplicationUrlPattern'), rowNo))
        }
      }
    } else {
      if (row.applicationName.value()) {
        this.errors.push(format(message('concierge.categories.importValidation.questions.isApplicationUrlNull'), rowNo))
      }
    }
  }

  private maxSection() {
    let maxSectionNo = 0
    this.questionRows.forEach(row => {
      //3行目以降チェック
      if (row.sectionNo.rowNumber() > 2) {
        if (typeof row.sectionNo.value() !== 'undefined') {
          maxSectionNo = Number(row.sectionNo.value())
        }
      }
    })
    return maxSectionNo
  }

  private checkSkipSection(row: {[key: string]: Cell}, rowNo: number, maxSectionNo: number, beforeSectionRow: {[key: string]: Cell} ) {
    const skipYes = row.skipYes.value()
    const skipNo = row.skipNo.value()
    let currentSection:number | string = 0
    if (Object.keys(beforeSectionRow).length) {
      currentSection = beforeSectionRow.sectionNo.value() as number | string
    }
    // はい
    if (skipYes !== undefined && skipYes !== null) {
      if (typeof skipYes == "number") {
        if (typeof currentSection == "number" && skipYes <= currentSection) {
          this.errors.push(format(message('concierge.categories.importValidation.questions.lessSkipYesSectionNo'), rowNo))
        } else if (skipYes > maxSectionNo) {
          this.errors.push(format(message('concierge.categories.importValidation.questions.overSkipYesSectionNo'), rowNo))
        } else if (skipYes == 0) {
          this.errors.push(format(message('concierge.categories.importValidation.questions.invalidSkipYesPattern'), rowNo))
        }
      } else {
        if (skipYes != "次のセクション" && skipYes != "質問終了") {
          this.errors.push(format(message('concierge.categories.importValidation.questions.invalidSkipYesPattern'), rowNo))
        }
      }
    }
    // いいえ
    if (skipNo !== undefined && skipNo !== null) {
      if (typeof skipNo == "number") {
        if (typeof currentSection == "number" && skipNo <= currentSection ) {
          this.errors.push(format(message('concierge.categories.importValidation.questions.lessSkipNoSectionNo'), rowNo))
        } else if (skipNo > maxSectionNo) {
          this.errors.push(format(message('concierge.categories.importValidation.questions.overSkipNoSectionNo'), rowNo))
        } else if (skipNo == 0) {
          this.errors.push(format(message('concierge.categories.importValidation.questions.invalidSkipNoPattern'), rowNo))
        }
      } else {
        if (skipNo != "次のセクション" && skipNo != "質問終了") {
          this.errors.push(format(message('concierge.categories.importValidation.questions.invalidSkipNoPattern'), rowNo))
        }
      }
    }
  }

  private getSkipVal(name: string) {
    if (name === "次のセクション") {
      return 'next'
    } else if (name === "質問終了") {
      return 'end'
    } else {
      return name
    }
  }

  //カテゴリインポート時は質問シート以外の中身はチェック不要(2022/10/7)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private validateProcess(row: {[key: string]: Cell}, rowNo: number) {
    // nop
  }

  //カテゴリインポート時は質問シート以外の中身はチェック不要(2022/10/7)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private validateDepartment(row: {[key: string]: Cell}, rowNo: number) {
    // nop
  }
}