
import {computed, defineComponent, PropType} from 'vue'
import InputUrl from '@/components/ui/InputUrl.vue'
import IconCircleMinus from '@/components/ui/IconCircleMinus.vue'
import IconCirclePlus from '@/components/ui/IconCirclePlus.vue'
import InputText from "@/components/ui/InputText.vue";

interface Link {
  name: string,
  url: string
}

export default defineComponent({
  components: {InputText, InputUrl, IconCircleMinus, IconCirclePlus },
  props: {
    required: { type: Boolean, default: false },
    modelValue: { type: Array as PropType<Link[]>, default:() => { return [] }},
    labelName: { type: String, default: 'リンク名' },
    labelUrl: { type: String, default: 'URL' },
    minLength: { type: Number, default: 0 },
    maxLength: { type: Number, required: true }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const links = computed({
      get: () => props.modelValue,
      set: (val: Array<Link>) => (emit('update:modelValue', val))
    })
    const addLink = () => {
      if (links.value.length >= props.maxLength) {
        return
      }
      links.value.push({ name: '', url: '' })
    }
    const removeLink = (index: number) => {
      links.value.splice(index, 1)
    }

    // 最小数に満たない場合は空のリンクを追加
    for (let i = links.value.length; i < props.minLength; i++) {
      addLink()
    }

    return {
      links,
      addLink,
      removeLink
    }
  }
})
