
import { defineComponent, computed, ref } from 'vue'
import { useValidator } from './validator'
import { usePrefecture } from "@/plugins/prefecture";

export default defineComponent({
  props: {
    required: { type: Boolean, default: false },
    modelValue: { type: [String, Number], default: '' },
    label: { type: String, default: '都道府県' },
    name: { type: String, default: 'prefectures' },
    id: { type: String, default: 'prefectures' },
    emptyString: { type: String, default: '' },
    // 値に使う項目を指定
    item: {
      type: String,
      default: 'id',
      validator(this: void, item: string) {
        return ['id', 'name'].includes(item)
      }
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { error, validateSelect } = useValidator()
    const pref = computed({
      get: () => props.modelValue,
      set: (val: string|number) => (emit('update:modelValue', val))
    })
    const prefectures = ref(usePrefecture().getPrefectures())

    const getValue = (prefecture: any): string => {
      return prefecture[props.item]
    }
    const validate = () => {
      validateSelect({
        value: String(pref.value),
        label: props.label,
        required: props.required
      })
    }

    return {
      prefectures,
      pref,
      error,
      getValue,
      validate
    }
  }
})
