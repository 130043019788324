
import { defineComponent, computed } from 'vue'
import { useValidator } from './validator'

export default defineComponent({
  props: {
    required: { type: Boolean, default: false },
    modelValue: { type: [String, Object], default: '' },
    label: { type: String, default: '' },
    emptyString: { type: String, default: '' },
    options: { type: Array, required: true },
    // 選択肢の表示に使う項目を指定
    text: { type: String, default: 'name' },
    // valueに使う項目を指定
    result: { type: String, default: 'option' },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { error, validateSelect } = useValidator()
    const select = computed({
      get: () => props.modelValue,
      set: (val: string|object) => (emit('update:modelValue', val))
    })
    const validate = () => {
      validateSelect({
        value: typeof select.value === "string"||null ? select.value : select.value[props.text],
        label: props.label,
        required: props.required,
      })
    }
    const getName = (option: any): string => {
      return typeof option === "string" ? option : option[props.text]
    }
    const getValue = (option: any): string => {
      return props.result === 'option' ? option : option[props.result]
    }
    return {
      select,
      error,
      validate,
      getName,
      getValue
    }
  }
})
