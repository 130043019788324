
import {defineComponent, computed, reactive, ref, inject} from 'vue'
import InputSelect from "@/components/ui/InputSelect.vue";
import {injectKey, Nicotto } from "@/plugins/nicotto";

export default defineComponent({
  components: {InputSelect},
  props: {
    required: { type: Boolean, default: false },
    modelValue: { type: Object, default: () => ({})},
    categoryId: { type: String, required:true },
    sectionId: { type: String, required:true }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const keys = ['yes', 'no']
    const skip = reactive<any>({})
    keys.forEach(key => {
      skip[key] = computed({
        get() {
          return props.modelValue[key]
        },
        set(val) {
          emit('update:modelValue', { ...props.modelValue, [key]: val})
        }
      })
    })

    const sections = ref<any[]>([])
    const nicotto = inject(injectKey) as Nicotto
    nicotto.concierge.getSectionList(props.categoryId)
      .then(data => {
        if (props.sectionId !== "") {
          sections.value = data.filter(section => parseInt(section.sectionId) > parseInt(props.sectionId))
          sections.value.forEach(section => {
            section['text'] = section.sectionId + '：' + section.name
          })
        }
        sections.value.unshift({text:'次のセクション', sectionId:'next'}, {text:'質問終了', sectionId:'end'})
      })

    return {
      skip,
      sections
    }
  }
})
