
import { defineComponent, computed } from 'vue'
import InputText from '@/components/ui/InputText.vue'

export default defineComponent({
  components: { InputText },
  props: {
    required: { type: Boolean, default: false },
    modelValue: { type: String, default: '' },
    label: { type: String, default: 'メールアドレス' }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const email = computed({
      get: () => props.modelValue,
      set: (val: string) => (emit('update:modelValue', val))
    })

    return {
      email,
      pattern: '^\\w+([+\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$'
    }
  }
})
