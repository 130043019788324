<template>
  <div>
    <router-link to="/signin" class="btn btn-sm btn-outline-light me-2" role="button">
      ログイン
    </router-link>
    <router-link to="/application" class="btn btn-sm btn-warning text-white" role="button">
      利用申込み
    </router-link>
  </div>
</template>
