export const listDepartments = /* GraphQL */ `
  query listDepartments(
    $id: ID
    $sortKey: ModelIDKeyConditionInput
    $filter: ModelMunicipalityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMunicipalities(
      id: $id
      sortKey: $sortKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sortKey
        name
        locations
        tel
        orderSeq
        url
        note
      }
      nextToken
    }
  }
`;

export const listProcesses = /* GraphQL */ `
  query listProcesses(
    $type: String!
    $orderBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMunicipalityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMunicipalityByType(
      type: $type
      orderBy: $orderBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sortKey
        name
        link {
          name
          url
        }
        code
      }
      nextToken
    }
  }
`
export const listSections = /* GraphQL */ `
  query listSections(
    $type: String!
    $orderBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMunicipalityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMunicipalityByType(
      type: $type
      orderBy: $orderBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sortKey
        name
        type
        orderBy
        skip {
          yes
          no
        }
        supplement
        question
      }
      nextToken
    }
  }
`
export const listQuestions = /* GraphQL */ `
  query listQuestions(
    $type: String!
    $orderBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMunicipalityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMunicipalityByType(
      type: $type
      orderBy: $orderBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sortKey
        name
        type
        orderBy
        skip {
          yes
          no
        }
        supplement
        question
        processes {
          processId
          name
          condition
          belongings
          additionalContacts
          supplement
          eApplication {
            link {
              name
              url
            }
            supplement
          }
          links {
            name
            url
          }
          note
        }
      }
      nextToken
    }
  }
`
export const getConciergeQuestion = /* GraphQL */ `
  query GetConciergeQuestion($id: ID!, $sortKey: ID!) {
    getMunicipality(id: $id, sortKey: $sortKey) {
      id
      sortKey
      name
      orderBy
      skip {
        yes
        no
      }
      supplement
      question
      processes {
        condition
        processId
        name
        belongings
        additionalContacts
        supplement
        eApplication {
          link {
            name
            url
          }
          supplement
        }
        links {
          name
          url
        }
        note
      }
      createdAt
      updatedAt
    }
  }
`