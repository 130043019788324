import { App, InjectionKey, inject, readonly } from 'vue'
import { Concierge } from './concierge'
import { Auth } from './auth'
import { adminQueries } from './account'

const nicotto = {
  auth: Auth,
  concierge: Concierge,
  account: adminQueries
}
export type Nicotto = typeof nicotto

export const injectKey: InjectionKey<Nicotto> = Symbol('nicotto')
export default {
  install: (app: App) => {
    app.config.globalProperties.$nicotto = nicotto
    app.provide<Nicotto>(injectKey, readonly(nicotto))
  }
}
export * from './types'
export { User, Municipality } from './account'
export {
  ConciergeDepartment, ConciergeProcess,
  ConciergeCategory, ConciergeSection, ConciergeQuestion, ConciergeQuestionProcess
} from './concierge'
export { Auth } from './auth'

// Composition API
export function useNicotto() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return inject(injectKey)!
}
