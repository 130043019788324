import { v4 as uuidv4 } from 'uuid'
import { API, graphqlOperation, Logger, Storage } from 'aws-amplify'
import { getMunicipality, getConciergeCategory, getConciergeDepartment, listMunicipalities, listMunicipalityByType, generateSignedUrl, getGoogleAnalyticsDataAPICount } from '../graphql/queries'
import {
  createMunicipality, updateMunicipality, deleteMunicipality,
  batchImportConciergeDepartment, putConciergeDepartment,
  batchImportConciergeProcess, updateConciergeCategory, batchImportConciergeQuestion,
  createConciergeDepartment, createConciergeCategory, updateSortConciergeList, deleteConciergeCategory, publishConcierge, createConciergeProcess, updateConciergeProcess
} from '../graphql/mutations'
import { listDepartments, listProcesses, listSections, listQuestions, getConciergeQuestion } from '../graphql/concierge'
import { createCsv, parseCsv } from '../utils'
import {
  ConciergeCategory, ConciergeSection, ConciergeQuestion, ConciergeQuestionProcess,
  CategoryExcelImporter, CategoryExcel, getCategoryExcelTemplate
} from './category'
import { ConciergeProcess, ProcessCsvFields, ProcessCsvImporter } from './process'
import { ConciergeDepartment, DepartmentCsvFields, DepartmentCsvImporter } from './department'
import { ConciergeSiteSetting, ConciergePublishModeType } from '../types'
import { GoogleAnalyticsDataApiData, ModelIDKeyConditionInput, PublishConciergeMutationVariables } from '../types/api'

export {
  ConciergeDepartment, ConciergeProcess,
  ConciergeCategory, ConciergeSection, ConciergeQuestion, ConciergeQuestionProcess
}

const logger = new Logger('concierge')

const concierge = {
  municipalityId: '',
  filePrefix() {
    return this.municipalityId + '/'
  },
  async getFile(path: string, options: any = {}) {
    return await Storage.get(this.filePrefix() + path, options)
  },
  async putFile(path: string, file: File, metadata: any = {}) {
    // マルチバイト文字はそのままでは使用不可
    // const metadata = { filename: file.name }
    await Storage.put(this.filePrefix() + path, file, { contentType: file.type, metadata })
  },
  async removeFile(path: string) {
    await Storage.remove(this.filePrefix() + path)
  },
  async getList(sortKey: ModelIDKeyConditionInput = {}): Promise<any[]> {
    const input = { id: this.municipalityId, sortKey }
    const result: any = await API.graphql(graphqlOperation(listMunicipalities, input))
    return result.data.listMunicipalities.items
  },
  async getCategoryList(): Promise<ConciergeCategory[]> {
    const input = {
      type: ConciergeCategory.type(this.municipalityId)
    }
    const result: any = await API.graphql(graphqlOperation(listMunicipalityByType, input))
    return result.data.listMunicipalityByType.items.map((category: any) => {
      return new ConciergeCategory(category)
    })
  },
  async getCategory(categoryId: string): Promise<ConciergeCategory> {
    const input = { municipalityId: this.municipalityId, categoryId: categoryId }
    const result: any = await API.graphql(graphqlOperation(getConciergeCategory, input))
    const category = result.data.getConciergeCategory
    if (category == null) {
      throw new Error("質問カテゴリーが見つかりません");
    }

    // アイコン
    if (category.iconPath) {
      try {
        category.iconUrl = await this.getFile(category.iconPath)
      } catch (err) {
       console.error(err) 
      }
    }
    return new ConciergeCategory(category)
  },
  async createCategory(category: ConciergeCategory, iconImage: File|null) {
    // アイコン
    if (iconImage) {
      category.iconPath = uuidv4()
      await this.putFile(category.iconPath, iconImage)
    }

    const input = {
      municipalityId: this.municipalityId,
      input: category.toInput()
    }
    await API.graphql(graphqlOperation(createConciergeCategory, input))
  },
  async updateCategory(category: ConciergeCategory, iconImage: File|null) {
    // アイコン
    if (iconImage) {
      category.iconPath = category.iconPath || uuidv4()
      await this.putFile(category.iconPath, iconImage)
    }

    const input = {
      municipalityId: this.municipalityId,
      input: category.toInput()
    }
    await API.graphql(graphqlOperation(updateConciergeCategory, input))
  },
  async deleteCategory(category: ConciergeCategory) {
    // データ削除(質問・セクションも削除)
    const input = { municipalityId: this.municipalityId, input: category.toInput() }
    await API.graphql(graphqlOperation(deleteConciergeCategory, input))

    // アイコン削除
    if (category.iconPath) {
      await this.removeFile(category.iconPath)
    }
  },
  async getCategoryExcel(categoryId: string): Promise<Blob> {
    const excel = await CategoryExcel.createFromData(await getCategoryExcelTemplate())
    const departments = await this.getDepartmentList()
    excel.renderDepartmentSheet(departments)
    excel.renderProcessSheet(await this.getProcessList(true))
    await excel.renderQuestionSheet(
      await this.getSectionList(categoryId),
      departments,
      (sectionId: string) => {
        return this.getQuestionList(categoryId, sectionId)
      }
    )
    return excel.toBlob()
  },
  async createCategoryImporter(category: ConciergeCategory, data: ArrayBuffer|Uint8Array|Buffer|Blob) {
    return new CategoryExcelImporter(
      category,
      await CategoryExcel.createFromData(data),
      await this.getProcessList(),
      await this.getDepartmentList()
    )
  },
  async updateSortCategoryList(items: Array<ConciergeCategory>) {
    const orderList: { sortKey: any; orderBy: any }[] = []
    if (items == null) {
      throw new Error("カテゴリが見つかりません");
    }
    items.map((item, index) => {
      orderList.push({ 'sortKey': item.sortKey(), 'orderBy': (index+1).toString().padStart(3, '0') })
    })
    const input = {
      municipalityId: this.municipalityId,
      input: orderList
    }
    const result: any = await API.graphql(graphqlOperation(updateSortConciergeList, input))
    logger.info('updateSortConciergeCategory', result)
    return result.data.updateSortConciergeList
  },
  async updateSortQuestionList(items: Array<ConciergeQuestion>) {
    const orderList: { sortKey: any; orderBy: any }[] = []
    if (items == null) {
      throw new Error("質問が見つかりません");
    }
    items.map((item, index) => {
      orderList.push({ 'sortKey': item.sortKey(), 'orderBy': (index+1).toString().padStart(3, '0') })
    })
    const input = {
      municipalityId: this.municipalityId,
      input: orderList
    }
    const result: any = await API.graphql(graphqlOperation(updateSortConciergeList, input))
    logger.info('updateSortConciergeCategory', result)
    return result.data.updateSortConciergeList
  },
  async getSectionList(categoryId: string): Promise<ConciergeSection[]> {
    const input = {
      type: ConciergeSection.type(this.municipalityId, categoryId)
    }
    const result: any = await this.getListBeyondLimit(input, listSections)
    return result.map((section: any) => new ConciergeSection(section))
  },
  async getSection(categoryId: string, sectionId: string): Promise<ConciergeSection> {
    const input = {
      id: this.municipalityId,
      sortKey: ConciergeSection.keyPrefix(categoryId) + sectionId
    }
    const result: any = await API.graphql(graphqlOperation(getMunicipality, input))
    const section = result.data.getMunicipality
    if (section == null) {
      throw new Error("セクションが見つかりません");
    }
    return new ConciergeSection(section)
  },
  async createSection(section: ConciergeSection) {
    section.sectionId = new Number((await this.getSectionList(section.categoryId)).length + 1).toString()
    const input = section.toInput(this.municipalityId)
    const result = await API.graphql(graphqlOperation(createMunicipality, { input: input }))
    logger.info('createSection', result)
  },
  async updateSection(section: ConciergeSection) {
    const input = section.toInput(this.municipalityId)
    const result = await API.graphql(graphqlOperation(updateMunicipality, { input: input }))
    logger.info('updateSection', result)
  },
  async batchImportConciergeQuestion(items: Array<{ item: ConciergeSection|ConciergeQuestion, deleted: boolean }>) {
    const input = {
      input: items.map(item => {
        return { ...item.item.toInput(this.municipalityId), deleted: item.deleted }
      })
    }
    await API.graphql(graphqlOperation(batchImportConciergeQuestion, input))
  },
  async getQuestionList(categoryId: string, sectionId: string): Promise<ConciergeQuestion[]> {
    const input = {
      type: ConciergeQuestion.type(this.municipalityId, categoryId, sectionId)
    }
    const result: any = await this.getListBeyondLimit(input, listQuestions)
    const processMap = await this.getProcessMap()
    return result.map((question: any) => {
      // 手続き
      question.processes = question.processes?.map((p: any) => {
        const process = processMap.get(p.processId) || { name: '' }
        p.process = { id: p.processId, name: process.name }
        return new ConciergeQuestionProcess(p)
      })
      return new ConciergeQuestion(question)
    })
  },
  async getQuestion(categoryId: string, sectionId: string, questionId: string): Promise<ConciergeQuestion> {
    const input = {
      id: this.municipalityId,
      sortKey: new ConciergeQuestion({categoryId, sectionId, questionId}).sortKey()
    }
    const result: any = await API.graphql(graphqlOperation(getConciergeQuestion, input))
    const question = result.data.getMunicipality
    if (question == null) {
      throw new Error("質問が見つかりません");
    }
    const processMap = await this.getProcessMap()
    question.processes = question.processes?.map((p: any) => {
      const process = processMap.get(p.processId) || { name: '' }
      p.process = { id: p.processId, name: process.name }
      return new ConciergeQuestionProcess(p)
    })
    return new ConciergeQuestion(question)
  },
  async createQuestion(question: ConciergeQuestion) {
    const input = question.toInput(this.municipalityId)
    await API.graphql(graphqlOperation(createMunicipality, { input: input }))
  },
  async updateQuestion(question: ConciergeQuestion) {
    const input = question.toInput(this.municipalityId)
    await API.graphql(graphqlOperation(updateMunicipality, { input: input }))
  },
  async deleteQuestion(question: ConciergeQuestion) {
    const input = { id: this.municipalityId, sortKey: question.sortKey() }
    await API.graphql(graphqlOperation(deleteMunicipality, { input: input }))
  },
  async getDepartmentList(): Promise<ConciergeDepartment[]> {
    const input = {
      id: this.municipalityId,
      sortKey: {
        beginsWith: ConciergeDepartment.keyPrefix
      }
    }
    const result: any = await this.getListBeyondLimit(input, listDepartments)
    return result.map((item: any) => new ConciergeDepartment(item))
  },
  async getDepartmentDetailList(): Promise<ConciergeDepartment[]> {
    const input = {
      id: this.municipalityId,
      sortKey: {
        beginsWith: 'concierge#department'
      }
    }
    const result: any = await this.getListBeyondLimit(input, listDepartments)
    const deptMap = new Map()
    result.forEach((d: any) => {
      if (d.sortKey.startsWith(ConciergeDepartment.keyPrefix)) {
        const conciergeDepartment =  new ConciergeDepartment(d)
        deptMap.set(conciergeDepartment.code, conciergeDepartment)
      } else {
        const code = d.sortKey.replace('concierge#departmentDetail#', '')
        const department = deptMap.get(code)
        department['note'] = d['note']
        department['orderSeq'] = d['orderSeq']
        department['url'] = d['url']
      }
    })
    return Array.from(deptMap.values())
  },
  async getDepartment(code: string): Promise<ConciergeDepartment> {
    const input = {
      municipalityId: this.municipalityId,
      code: code
    }
    const result: any = await API.graphql(graphqlOperation(getConciergeDepartment, input))
    const department = result.data.getConciergeDepartment
    if (department == null) {
      throw new Error("担当課が見つかりません");
    }
    return new ConciergeDepartment(department)
  },
  async createDepartment(department: ConciergeDepartment) {
    const input = {
      municipalityId: this.municipalityId,
      input: department
    }
    const result = await API.graphql(graphqlOperation(createConciergeDepartment, input))
    logger.info('createDepartment', result)
  },
  async updateDepartment(department: ConciergeDepartment) {
    const input = {
      municipalityId: this.municipalityId,
      input: department
    }
    const result = await API.graphql(graphqlOperation(putConciergeDepartment, input))
    logger.info('updateDepartment', result)
  },
  async deleteDepartment(department: ConciergeDepartment) {
    const input = {
      municipalityId: this.municipalityId,
      input: { ...department, deleted: true }
    }
    const result = await API.graphql(graphqlOperation(putConciergeDepartment, input))
    logger.info('deleteDepartment', result)
  },
  async batchImportDepartment(departments: ConciergeDepartment[]) {
    const input = {
      municipalityId: this.municipalityId,
      input: departments
    }
    const result = await API.graphql(graphqlOperation(batchImportConciergeDepartment, input))
    logger.info('batchImportDepartment', result)
  },
  async getDepartmentCsv(): Promise<Blob> {
    const data = (await this.getDepartmentDetailList()).map(item => item.flatten())
    return createCsv(data, DepartmentCsvFields)
  },
  createDepartmentImporter(csv: string) {
    const result = parseCsv(csv, Object.keys(DepartmentCsvFields))
    const header = result.data.shift() as object
    return new DepartmentCsvImporter(result.data as object[], header)
  },
  async getProcessList(withDepartment = false): Promise<ConciergeProcess[]> {
    const input = {
      type: ConciergeProcess.type(this.municipalityId)
    }
    const result: any = await this.getListBeyondLimit(input, listProcesses)
    let deptMap: Map<string, ConciergeDepartment>
    if (withDepartment) {
      deptMap = new Map((await this.getDepartmentList()).map(
        department => [department.code, department]
      ))
    }
    return (await Promise.all(result.map(async(item: any) => {
      if (item.code && deptMap) {
        item.department = deptMap.get(item.code)
      }
      return new ConciergeProcess(item)
    })))
  },
  async getProcessMap(): Promise<Map<string, ConciergeProcess>> {
    return new Map((await this.getProcessList()).map(process => {
      return [process.processId, process]
    }))
  },
  async getProcess(processId: string): Promise<ConciergeProcess> {
    const input = {
      id: this.municipalityId,
      sortKey: `${ConciergeProcess.keyPrefix}${processId}`
    }
    const result: any = await API.graphql(graphqlOperation(getMunicipality, input))
    const process = result.data.getMunicipality
    if (process == null) {
      throw new Error("手続きが見つかりません");
    }
    if (process.code) {
      const department: any = await API.graphql(graphqlOperation(getMunicipality, {
        id: this.municipalityId,
        sortKey: `${ConciergeDepartment.keyPrefix}${process.code}`
      }))
      process.department = new ConciergeDepartment(department.data.getMunicipality || {})
    }
    return new ConciergeProcess(process)
  },
  async createProcess(process: ConciergeProcess) {
    const input = {
      municipalityId: this.municipalityId,
      input: process.toInput()
    }
    try {
      const result = await API.graphql(graphqlOperation(createConciergeProcess, input))
      logger.info('createProcess', result)
    } catch (err) {
      logger.error('createProcess', err)
      throw err
    }
  },
  async updateProcess(process: ConciergeProcess) {
    const input = {
      municipalityId: this.municipalityId,
      input: process.toInput()
    }
    try {
      const result = await API.graphql(graphqlOperation(updateConciergeProcess, input))
      logger.info('updateProcess', result)
    } catch (err) {
      logger.error('updateProcess', err)
      throw err
    }
  },
  async deleteProcess(process: ConciergeProcess) {
    const input = {
      municipalityId: this.municipalityId,
      input: [{ ...process.toInput(), deleted: true }]
    }
    try {
      const result = await API.graphql(graphqlOperation(batchImportConciergeProcess, input))
      logger.info('deleteProcess', result)
    } catch (err) {
      logger.error('deleteProcess', err)
      throw err
    }
  },
  async batchImportProcess(processes: ConciergeProcess[]) {
    const input = {
      municipalityId: this.municipalityId,
      input: processes
    }
    try {
      const result = await API.graphql(graphqlOperation(batchImportConciergeProcess, input))
      logger.info('batchImportProcess', result)
    } catch (err) {
      logger.error('batchImportProcess', err)
      throw err
    }
  },
  async getProcessCsv(): Promise<Blob> {
    console.log(this.getProcessList(true))
    const data = (await this.getProcessList(true)).map(item => item.flatten())
    console.log("data",data)
    return createCsv(data, ProcessCsvFields)
  },
  async createProcessImporter(csv: string) {
    const result = parseCsv(csv, Object.keys(ProcessCsvFields))
    const header = result.data.shift() as object
    const importer = new ProcessCsvImporter(result.data as object[], header)
    importer.setDepartments(await this.getDepartmentList())
    return importer
  },
  async getSiteSetting(): Promise<ConciergeSiteSetting> {
    const input = { id: this.municipalityId, sortKey: "concierge#setting" }
    const result: any = await API.graphql(graphqlOperation(getMunicipality, input))
    const setting = result.data.getMunicipality
    if (setting == null) {
      throw new Error("サイト情報が見つかりません");
    }
    // ロゴ画像
    if (setting.iconPath) {
      try {
        setting.iconUrl = await this.getFile(setting.iconPath)
      } catch (err) {
        console.error(err) 
      }
    }
    return setting
  },
  async updateSiteSetting(setting: ConciergeSiteSetting, iconImage: File|null) {
    // ロゴ画像追加・更新
    if (iconImage) {
      setting.iconPath = setting.iconPath || uuidv4()
      await this.putFile(setting.iconPath, iconImage)
    }

    const input = {
      id: this.municipalityId,
      sortKey: "concierge#setting",
      name: setting.name,
      description: setting.description,
      contact: setting.contact,
      iconPath: setting.iconPath
    }
    await API.graphql(graphqlOperation(updateMunicipality, { input: input }))
  },
  async publishConcierge(mode: ConciergePublishModeType, categoryId?: string) {
    const input: PublishConciergeMutationVariables = {
      municipalityId: this.municipalityId,
      mode: mode
    }
    if (categoryId) {
      input.categoryId = categoryId
    }
    // サイトコンテンツ生成(非公開の場合は削除)
    await API.graphql(graphqlOperation(publishConcierge, input))

    // 公開状態を保存
    if (mode != 'preview') {
      await API.graphql(graphqlOperation(updateMunicipality, { input: {
        id: this.municipalityId,
        sortKey: "concierge#setting",
        publishMode: mode
      } }))
    }
  },
  async getPreviewUrl(path_prefix: string, category?: ConciergeCategory) {
    const path = `/preview/${path_prefix}/`
    const result: any = await API.graphql(graphqlOperation(generateSignedUrl, { 
      path: `${path}${category?.pageId || 'index'}.html`,
      resource: path + '*'
    }))
    logger.info('getPreviewUrl', result.data.generateSignedUrl.signed_url)
    return result.data.generateSignedUrl.signed_url
  },
  async getListBeyondLimit(input:any, operation:any) {
    let result: any
    let items: Array<any> = []
    let type: any
    do {
      result = await API.graphql(graphqlOperation(operation, input))
      type = Object.keys(result.data)[0]
      input.nextToken = result.data[type].nextToken
      items = items.concat(result.data[type].items)
    } while (result.data[type].nextToken);
    return items
  },
  async getGoogleAnalyticsDataAPICount(path: string) {
    const input = {
      path: path
    }
    const result: any = await API.graphql(graphqlOperation(getGoogleAnalyticsDataAPICount, input))
    logger.info('getGoogleAnalyticsDataAPICount', result)
    return result.data.getGoogleAnalyticsDataAPICount
  },
  async getGoogleAnalyticsDataCsv(arrayData:Array<GoogleAnalyticsDataApiData>): Promise<Blob> {
    const data = arrayData
    const AnalyticsDataCsvFields = {
      page_path: 'ページURL',
      page_title: 'ページタイトル',
      screen_page_views: '視聴回数',
      sessions: 'セッション'
    } as const
    return createCsv(data, AnalyticsDataCsvFields)
  },
}

export type Concierge = typeof concierge
export const Concierge: Concierge = new Proxy(concierge, {
  get(target: any, prop) {
    // 自治体IDが未設定の場合は呼び出し不可
    if (prop != 'municipalityId' && !target.municipalityId) {
      throw new Error("municipalityId for concierge not set.")
    }
    return target[prop]
  }
})