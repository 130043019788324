import { vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "required", "pattern"]
const _hoisted_2 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("input", _mergeProps(_ctx.$attrs, {
      type: _ctx.type,
      class: "form-control",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event)),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validate && _ctx.validate(...args))),
      required: _ctx.required,
      pattern: _ctx.pattern
    }), null, 16, _hoisted_1), [
      [_vModelDynamic, _ctx.text]
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.error), 1)
  ], 64))
}