import { InjectionKey } from 'vue'
import { createStore, Store, useStore as baseUseStore } from 'vuex'
import { Municipality, User } from '@/plugins/nicotto'
import { Message } from '@/plugins/message'

export interface State {
  municipality: Municipality|null
  user: User|null,
  message: Message|null,
  showAlert: boolean,
  email: string|null,
  loading: boolean
}

export const storeKey: InjectionKey<Store<State>> = Symbol()
export function useStore() {
  return baseUseStore(storeKey)
}

export const store = createStore<State>({
  state: {
    municipality: null,
    user: null,
    message: null,
    showAlert: false,
    email: null,
    loading: true
  },
  getters: {
    isLogin: (state) => !!state.user,
    isAdmin: (state) => state.user && state.user.isAdmin()
  },
  mutations: {
    setUser(state, user: User|null) {
      state.user = user
    },
    setMunicipality(state, municipality: Municipality|null) {
      state.municipality = municipality
    },
    setMessage(state, message: Message|string) {
      state.showAlert = true
      state.message = typeof message == 'string'
        ? new Message(message)
        : message
      const displayTime = Number(process.env.VUE_APP_CONCIERGE_FLASH_MESSAGE_DISPLAY_TIME) || 2500
      if (state.message.dismiss) {
        setTimeout(() => {
          state.showAlert = false
        }, displayTime)
      }
    },
    setShowAlert(state, showAlert: boolean) {
      state.showAlert = showAlert
    },
    setEmail(state, email: string|null) {
      state.email = email
    },
    startLoading(state) {
      state.loading = false;
    },
    finishLoading(state) {
      state.loading = true;
    }
    
  },
  actions: {
  },
  modules: {
  }
})
