import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["required", "title", "name", "id"]
const _hoisted_2 = { value: "" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("select", {
      class: "form-select",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pref) = $event)),
      required: _ctx.required,
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validate && _ctx.validate(...args))),
      title: _ctx.label,
      name: _ctx.name,
      id: _ctx.id
    }, [
      _createElementVNode("option", _hoisted_2, _toDisplayString(_ctx.emptyString), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prefectures, (prefecture) => {
        return (_openBlock(), _createElementBlock("option", {
          key: prefecture.id,
          value: _ctx.getValue(prefecture)
        }, _toDisplayString(prefecture.name), 9, _hoisted_3))
      }), 128))
    ], 40, _hoisted_1), [
      [_vModelSelect, _ctx.pref]
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.error), 1)
  ], 64))
}