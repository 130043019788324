/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const setupMunicipalityData = /* GraphQL */ `
  mutation SetupMunicipalityData(
    $municipalityId: String!
    $services: NicottoServicesInput
  ) {
    setupMunicipalityData(municipalityId: $municipalityId, services: $services)
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount($municipality: MunicipalityInput!, $user: UserInput!) {
    updateAccount(municipality: $municipality, user: $user)
  }
`;
export const publishConcierge = /* GraphQL */ `
  mutation PublishConcierge(
    $municipalityId: String!
    $mode: String!
    $categoryId: String
  ) {
    publishConcierge(
      municipalityId: $municipalityId
      mode: $mode
      categoryId: $categoryId
    )
  }
`;
export const batchImportConciergeDepartment = /* GraphQL */ `
  mutation BatchImportConciergeDepartment(
    $municipalityId: String!
    $input: [ConciergeDepartmentInput!]!
  ) {
    batchImportConciergeDepartment(
      municipalityId: $municipalityId
      input: $input
    ) {
      code
      name
      locations
      tel
      url
      orderSeq
      note
    }
  }
`;
export const batchImportConciergeProcess = /* GraphQL */ `
  mutation BatchImportConciergeProcess(
    $municipalityId: String!
    $input: [ConciergeProcessInput!]!
  ) {
    batchImportConciergeProcess(
      municipalityId: $municipalityId
      input: $input
    ) {
      processId
      name
      department {
        code
        name
        locations
        tel
        url
        orderSeq
        note
      }
      link {
        name
        url
      }
      refId
    }
  }
`;
export const batchImportConciergeQuestion = /* GraphQL */ `
  mutation BatchImportConciergeQuestion($input: [ConciergeQuestionInput!]!) {
    batchImportConciergeQuestion(input: $input) {
      id
      sortKey
      name
      question
      supplement
      skip {
        yes
        no
      }
      processes {
        condition
        processId
        name
        belongings
        additionalContacts
        supplement
        note
      }
      type
      orderBy
    }
  }
`;
export const createConciergeProcess = /* GraphQL */ `
  mutation CreateConciergeProcess(
    $municipalityId: String!
    $input: ConciergeProcessInput!
  ) {
    createConciergeProcess(municipalityId: $municipalityId, input: $input) {
      processId
      name
      department {
        code
        name
        locations
        tel
        url
        orderSeq
        note
      }
      link {
        name
        url
      }
      refId
    }
  }
`;
export const updateConciergeProcess = /* GraphQL */ `
  mutation UpdateConciergeProcess(
    $municipalityId: String!
    $input: ConciergeProcessInput!
  ) {
    updateConciergeProcess(municipalityId: $municipalityId, input: $input) {
      processId
      name
      department {
        code
        name
        locations
        tel
        url
        orderSeq
        note
      }
      link {
        name
        url
      }
      refId
    }
  }
`;
export const createConciergeDepartment = /* GraphQL */ `
  mutation CreateConciergeDepartment(
    $municipalityId: String!
    $input: ConciergeDepartmentInput!
  ) {
    createConciergeDepartment(municipalityId: $municipalityId, input: $input) {
      code
      name
      locations
      tel
      url
      orderSeq
      note
    }
  }
`;
export const putConciergeDepartment = /* GraphQL */ `
  mutation PutConciergeDepartment(
    $municipalityId: String!
    $input: ConciergeDepartmentInput!
  ) {
    putConciergeDepartment(municipalityId: $municipalityId, input: $input) {
      code
      name
      locations
      tel
      url
      orderSeq
      note
    }
  }
`;
export const createConciergeCategory = /* GraphQL */ `
  mutation CreateConciergeCategory(
    $municipalityId: String!
    $input: ConciergeCategoryInput!
  ) {
    createConciergeCategory(municipalityId: $municipalityId, input: $input) {
      categoryId
      pageId
      name
      description
      lead
      iconPath
      links {
        name
        url
      }
      orderBy
      refId
      displayCommonBelongings
      belongings
      startButtonName
      publishMode
    }
  }
`;
export const updateConciergeCategory = /* GraphQL */ `
  mutation UpdateConciergeCategory(
    $municipalityId: String!
    $input: ConciergeCategoryInput!
  ) {
    updateConciergeCategory(municipalityId: $municipalityId, input: $input) {
      categoryId
      pageId
      name
      description
      lead
      iconPath
      links {
        name
        url
      }
      orderBy
      refId
      displayCommonBelongings
      belongings
      startButtonName
      publishMode
    }
  }
`;
export const deleteConciergeCategory = /* GraphQL */ `
  mutation DeleteConciergeCategory(
    $municipalityId: String!
    $input: ConciergeCategoryInput!
  ) {
    deleteConciergeCategory(municipalityId: $municipalityId, input: $input)
  }
`;
export const updateSortConciergeList = /* GraphQL */ `
  mutation UpdateSortConciergeList(
    $municipalityId: String!
    $input: [NicottoOrderListInput]!
  ) {
    updateSortConciergeList(municipalityId: $municipalityId, input: $input) {
      sortKey
      orderBy
    }
  }
`;
export const createMunicipality = /* GraphQL */ `
  mutation CreateMunicipality(
    $input: CreateMunicipalityInput!
    $condition: ModelMunicipalityConditionInput
  ) {
    createMunicipality(input: $input, condition: $condition) {
      id
      sortKey
      name
      type
      orderBy
      refId
      services {
        concierge
      }
      locations
      tel
      prefecture
      path
      corporateNumber
      code
      department
      link {
        name
        url
      }
      skip {
        yes
        no
      }
      supplement
      description
      question
      iconPath
      pageId
      contact
      publishMode
      orderSeq
      url
      note
      processes {
        condition
        processId
        name
        belongings
        additionalContacts
        supplement
        note
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMunicipality = /* GraphQL */ `
  mutation UpdateMunicipality(
    $input: UpdateMunicipalityInput!
    $condition: ModelMunicipalityConditionInput
  ) {
    updateMunicipality(input: $input, condition: $condition) {
      id
      sortKey
      name
      type
      orderBy
      refId
      services {
        concierge
      }
      locations
      tel
      prefecture
      path
      corporateNumber
      code
      department
      link {
        name
        url
      }
      skip {
        yes
        no
      }
      supplement
      description
      question
      iconPath
      pageId
      contact
      publishMode
      orderSeq
      url
      note
      processes {
        condition
        processId
        name
        belongings
        additionalContacts
        supplement
        note
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMunicipality = /* GraphQL */ `
  mutation DeleteMunicipality(
    $input: DeleteMunicipalityInput!
    $condition: ModelMunicipalityConditionInput
  ) {
    deleteMunicipality(input: $input, condition: $condition) {
      id
      sortKey
      name
      type
      orderBy
      refId
      services {
        concierge
      }
      locations
      tel
      prefecture
      path
      corporateNumber
      code
      department
      link {
        name
        url
      }
      skip {
        yes
        no
      }
      supplement
      description
      question
      iconPath
      pageId
      contact
      publishMode
      orderSeq
      url
      note
      processes {
        condition
        processId
        name
        belongings
        additionalContacts
        supplement
        note
      }
      createdAt
      updatedAt
    }
  }
`;
