/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const generateSignedUrl = /* GraphQL */ `
  query GenerateSignedUrl(
    $path: String!
    $resource: String!
    $expire_hours: Int
  ) {
    generateSignedUrl(
      path: $path
      resource: $resource
      expire_hours: $expire_hours
    ) {
      signed_url
    }
  }
`;
export const getConciergeDepartment = /* GraphQL */ `
  query GetConciergeDepartment($municipalityId: String!, $code: String!) {
    getConciergeDepartment(municipalityId: $municipalityId, code: $code) {
      code
      name
      locations
      tel
      url
      orderSeq
      note
    }
  }
`;
export const getConciergeCategory = /* GraphQL */ `
  query GetConciergeCategory($municipalityId: String!, $categoryId: String!) {
    getConciergeCategory(
      municipalityId: $municipalityId
      categoryId: $categoryId
    ) {
      categoryId
      pageId
      name
      description
      lead
      iconPath
      links {
        name
        url
      }
      orderBy
      refId
      displayCommonBelongings
      belongings
      startButtonName
      publishMode
    }
  }
`;
export const getGoogleAnalyticsDataAPICount = /* GraphQL */ `
  query GetGoogleAnalyticsDataAPICount($path: String!) {
    getGoogleAnalyticsDataAPICount(path: $path) {
      page_path
      page_title
      screen_page_views
      sessions
    }
  }
`;
export const getMunicipality = /* GraphQL */ `
  query GetMunicipality($id: ID!, $sortKey: ID!) {
    getMunicipality(id: $id, sortKey: $sortKey) {
      id
      sortKey
      name
      type
      orderBy
      refId
      services {
        concierge
      }
      locations
      tel
      prefecture
      path
      corporateNumber
      code
      department
      link {
        name
        url
      }
      skip {
        yes
        no
      }
      supplement
      description
      question
      iconPath
      pageId
      contact
      publishMode
      orderSeq
      url
      note
      processes {
        condition
        processId
        name
        belongings
        additionalContacts
        supplement
        note
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMunicipalities = /* GraphQL */ `
  query ListMunicipalities(
    $id: ID
    $sortKey: ModelIDKeyConditionInput
    $filter: ModelMunicipalityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMunicipalities(
      id: $id
      sortKey: $sortKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sortKey
        name
        type
        orderBy
        refId
        locations
        tel
        prefecture
        path
        corporateNumber
        code
        department
        supplement
        description
        question
        iconPath
        pageId
        contact
        publishMode
        orderSeq
        url
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMunicipalityByType = /* GraphQL */ `
  query ListMunicipalityByType(
    $type: String!
    $orderBy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMunicipalityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMunicipalityByType(
      type: $type
      orderBy: $orderBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sortKey
        name
        type
        orderBy
        refId
        locations
        tel
        prefecture
        path
        corporateNumber
        code
        department
        supplement
        description
        question
        iconPath
        pageId
        contact
        publishMode
        orderSeq
        url
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
