
import { defineComponent, computed } from 'vue'
import { useValidator } from './validator'

export default defineComponent({
  props: {
    required: { type: Boolean, default: false },
    modelValue: { type: String, default: '' },
    label: { type: String, default: 'テキストエリア' },
    rows: { type: Number, default: 3 }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { error, validateText} = useValidator()
    const textArea = computed({
      get: () => props.modelValue,
      set: (val: string) => (emit('update:modelValue', val))
    })
    const validate = () => {
      validateText({
        value: textArea.value,
        label: props.label,
        required: props.required,
      })
    }

    return {
      textArea,
      error,
      validate
    }
  }
})
