
import { defineComponent, computed } from 'vue'
import { useValidator } from './validator'

export default defineComponent({
  props: {
    required: { type: Boolean, default: false },
    modelValue: { type: String, default: '' },
    label: { type: String, default: 'テキスト' },
    type: { type: String, default: 'text' },
    pattern: { type: String, default: null },
    invalidPatternMessage: { type: String, default: '' }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { error, validateText } = useValidator()
    const text = computed({
      get: () => props.modelValue,
      set: (val: string) => (emit('update:modelValue', val))
    })
    const validate = () => {
      validateText({
        value: text.value,
        label: props.label,
        required: props.required,
        pattern: props.pattern,
        invalidPatternMessage: props.invalidPatternMessage
      })
    }

    return {
      text,
      error,
      validate
    }
  }
})
