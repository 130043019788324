import prefectures from './prefectures.json'

const prefMap = new Map(prefectures.map(p => [p.id, p.name]))
export const usePrefecture = () => {
  return {
    getPrefectures: () => {
      return prefectures
    },
    getName: (id: number) => {
      return prefMap.get(id)
    }
  }
}
