import { Auth, API, graphqlOperation } from 'aws-amplify'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { getAccount, listUsers, listMunicipalities } from './graphql/account'
import { MunicipalityListFilter, UserListFilter, AccountStatusType, NicottoServiceType, MunicipalityServices } from './types'
import { setupMunicipalityData, updateAccount, updateMunicipality } from './graphql/mutations'
import { Concierge } from './concierge'

export class Municipality {

  static type = 'Municipality'
  static sortKey = 'municipality'

  readonly municipalityId: string
  name: string
  prefecture: number
  path?: string
  url?: string
  corporateNumber?: string
  code?: string
  note?: string
  private _orig_path: string|null
  private _services: MunicipalityServices = {
    concierge: false
  }

  constructor(data: any) {
    this.municipalityId = data.municipalityId || data.id
    this.name = data.name
    this.prefecture = data.prefecture
    this.path = data.path
    this.url = data.url
    this.corporateNumber = data.corporateNumber
    this.code = data.code
    this.note = data.note
    this._orig_path = data.orig_path || data.path
    if (data.services) {
      this._services = {
        concierge: !!data.services.concierge
      }
    }
  }

  status(): AccountStatusType {
    // 自治体のステータスは現状、「未承認」「有効」のみ
    // ※「無効」はログイン不可なので、ユーザのステータスで判定
    for (const k of Object.keys(this._services)) {
      if (this._services[k as NicottoServiceType]) {
        return 'enabled'
      }
    }
    return 'notAccepted'
  }

  copy() {
    return new Municipality({
      ...this,
      orig_path: this._orig_path,
      services: this._services
    })
  }

  getServices(): MunicipalityServices {
    return this._services
  }

  toInput() {
    return {
      id: this.municipalityId,
      sortKey: Municipality.sortKey,
      name: this.name,
      prefecture: this.prefecture,
      path: this.path,
      orig_path: this._orig_path,
      url: this.url,
      corporateNumber: this.corporateNumber,
      code: this.code,
      note: this.note,
      orderBy: new Number(this.prefecture).toString().padStart(2, '0') + '#' + this.name,
    }
  }
}

export class User {

  static type = 'User'
  static sortKey = 'municipality#user'

  readonly userId: string
  name: string
  email: string
  tel?: string
  department?: string
  municipality?: Municipality
  readonly enabled?: boolean
  readonly email_verified?: boolean
  current_password?: string
  new_password?: string

  constructor(userId: string, data: any, private loginUser?: any) {
    this.userId = userId
    this.email = data.email
    this.name = data.name
    this.tel = data.tel
    this.department = data.department
    if (data.municipality) {
      this.municipality = data.municipality
    }
    if (data.enabled) {
      this.enabled = data.enabled
    }
    if (data.email_verified) {
      this.email_verified = data.email_verified == 'true'
    }
    if (data.current_password) {
      this.current_password = data.current_password
    }
    if (data.new_password) {
      this.new_password = data.new_password
    }
  }

  status(): AccountStatusType {
    if (this.enabled) {
      if (this.municipality) {
        return this.municipality.status()
      }
      return 'enabled'
    } else {
      return 'disabled'
    }
  }

  /**
   * 管理者かどうか判定
   * ※本人がログイン時のみ利用可能
   */
  isAdmin(): boolean {
    const session = this.loginUser && this.loginUser.signInUserSession
    if (! session) {
      throw new Error("Not authenticated.");
    }
    const groups = session.accessToken.payload["cognito:groups"] || []
    return groups.includes('admin')
  }

  copy() {
    return new User(this.userId, this, this.loginUser)
  }

  toInput() {
    // emailはCognitoで保持し、DBには保存しない
    return {
      id: this.userId,
      sortKey: User.sortKey,
      name: this.name,
      tel: this.tel,
      department: this.department,
      orderBy: `${this.municipality?.municipalityId}#${this.name}`
    }
  }
}

export const Account = {
  /**
   * 自治体情報取得
   */
  getMunicipality: async(municipalityId: string) => {
    const municipality =  (await API.graphql(graphqlOperation(getAccount, {
      id: municipalityId,
      sortKey: Municipality.sortKey
    })) as any).data.getMunicipality
    return municipality ? new Municipality(municipality) : null
  },

  /**
   * ユーザ情報取得(DynamoDB)
   */
  getUser: async(userId: string) => {
    return (await API.graphql(graphqlOperation(getAccount, {
      id: userId,
      sortKey: User.sortKey
    })) as any).data.getMunicipality
  },

  /**
   * 自治体検索・一覧取得
   */
  getMunicipalityList: async(filter?: MunicipalityListFilter, nextToken?: string): Promise<{ items: Municipality[], nextToken: string }> => {
    const input: any = { type: Municipality.type, filter: {} }
    if (filter?.prefecture) {
      input.orderBy = { beginsWith: new Number(filter.prefecture).toString().padStart(2, '0') }
    }
    if (filter?.municipalityName) {
      input.filter.name = { contains: filter.municipalityName }
    }
    if (nextToken) {
      input.nextToken = nextToken
    }
    const result:any = await API.graphql(graphqlOperation(listMunicipalities, input))
    return {
      items: result.data.listMunicipalityByType.items.map((item:any) => new Municipality(item)),
      nextToken: result.data.listMunicipalityByType.nextToken
    }
  },
  
  /**
   * ユーザ検索・一覧取得(DynamoDB)
   */
  getUserList: async(filter?: UserListFilter, nextToken?: string): Promise<{ items: any[], nextToken: string }> => {
    const input:any = { type: User.type, filter: {} }
    if (filter?.municipalityId) {
      input.orderBy = { beginsWith: filter.municipalityId + '#' }
    }
    if (filter?.userName) {
      input.filter.name = { contains: filter.userName }
    }
    if (filter?.departmentName) {
      input.filter.department = { contains: filter.departmentName }
    }
    if (nextToken) {
      input.nextToken = nextToken
    }
    const result:any = await API.graphql(graphqlOperation(listUsers, input))
    return result.data.listMunicipalityByType
  },
  updateAccount: async(user: User) => {
    await API.graphql(graphqlOperation(updateAccount, {
      municipality: user.municipality?.toInput(),
      user: user.toInput()
    }))
  }
}

export const buildUserData = async(cognitoAttrs: CognitoUserAttribute[], municipality?: Municipality, dbUser?: any) => {
  const data = Object.assign({}, ...cognitoAttrs.map(attr => {
    return { [attr.Name]: attr.Value }
  }))

  // 自治体情報
  if (data['custom:municipalityId'] || municipality) {
    const user = dbUser || await Account.getUser(data.sub)
    data.municipality = municipality || await Account.getMunicipality(data['custom:municipalityId'])
    if (user) {
      data.name = user.name
      data.department = user.department
      data.tel = user.tel
    }
  }
  return data
}

const createHeaders = async() => {
  const  session = await Auth.currentSession()
  return {
    'Content-Type': 'application/json',
    Authorization: session.getAccessToken().getJwtToken()
  }
}

/**
 * Ref. https://docs.amplify.aws/cli/auth/admin/#admin-queries-api
 */
export const adminQueries = {
  apiName: 'AdminQueries',
  async getCognitoUser(userId: string): Promise<any> {
    const init = {
      queryStringParameters: { username: userId },
      headers: await createHeaders()
    }
    return API.get(this.apiName, '/getUser', init)
  },
  async getUser(userId: string): Promise<User> {
    const user = await this.getCognitoUser(userId)
    const attrs = user.UserAttributes.map((attr:any) => new CognitoUserAttribute(attr))
    const data = await buildUserData(attrs)
    data.enabled = user.Enabled
    return new User(user.Username, data)
  },
  async getCognitoUserList(nextToken?: string): Promise<{items: any[], nextToken: string }> {
    const init: any = { headers: await createHeaders() }
    if (nextToken) {
      init.queryStringParameters = { token: nextToken }
    }
    const { NextToken, Users } = await API.get(this.apiName, '/listUsers', init)
    return { items: Users, nextToken: NextToken }
  },

  /**
   * 自治体ユーザ検索・一覧取得
   */
  async getMunicipalityUserList(filter: UserListFilter = {}): Promise<User[]> {
    let nextToken

    // 検索条件に一致するDBユーザ
    const dbUsers: any = {}
    nextToken = null
    do {
      const result: any = await Account.getUserList(filter, nextToken)
      result.items.forEach((u: any) => {
        dbUsers[u.id] = u
      })
      nextToken = result.nextToken
    } while (nextToken)

    // CognitoUserとDBユーザの紐づけ
    const tmpUsers: any = {} // キーは自治体ID、値は該当自治体のユーザの配列
    nextToken = null
    do {
      const result: any = await this.getCognitoUserList(nextToken)
      result.items.forEach((u: any) => {
        const attrs = u.Attributes
        const municipalityIndex = attrs.findIndex((attr: any) => attr.Name == 'custom:municipalityId')
        // 管理者(自治体ID設定なし)またはdbUserなし
        if (municipalityIndex == -1 || !dbUsers[u.Username]) {
          return
        }
        if (!tmpUsers[attrs[municipalityIndex].Value]) {
          tmpUsers[attrs[municipalityIndex].Value] = []
        }
        tmpUsers[attrs[municipalityIndex].Value].push({
          enabled: u.Enabled,
          attrs: attrs.map((attr:any) => new CognitoUserAttribute(attr)),
          user: dbUsers[u.Username]
        })
      })
      nextToken = result.nextToken
    } while (nextToken)

    const users: User[] = []
    nextToken = null
    do {
      // 自治体一覧取得
      const result = await Account.getMunicipalityList(filter)
      for (const municipality of result.items) {
        if (! tmpUsers[municipality.municipalityId]) {
          continue // ユーザなし
        }
        // 自治体のユーザー毎に処理
        // 同一自治体で複数ユーザにする場合は担当者名で並べ替えること
        for (const item of tmpUsers[municipality.municipalityId]) {
          // ユーザデータ構築
          const data = await buildUserData(item.attrs, municipality, item.user)
          data.enabled = item.enabled
          const user = new User(item.user.id, data)

          // ステータスでの絞り込み
          if (filter.status && user.status() != filter.status) {
            continue
          }
          users.push(user)
        }
      }

      // 全件取得
      nextToken = result.nextToken
    } while (nextToken)
    return users
  },
  async updateAccount(user: User) {
    if (! user.municipality) {
      throw new Error("管理者アカウントはこのメソッドで更新できません。")
    }
    await Account.updateAccount(user)
  },
  async updateUserEmail(userId: string, email: string, verified = false) {
    const init = {
      body: { username: userId, email: email, verified: verified },
      headers: await createHeaders()
    }
    await API.post(this.apiName, '/updateUserEmail', init)
  },
  async acceptMunicipality(municipality: Municipality, services: NicottoServiceType[]) {
    const input = {
      municipalityId: municipality.municipalityId,
      services: Object.assign({}, ...services.map(service => ({ [service]: true })))
    }
    await API.graphql(graphqlOperation(setupMunicipalityData, input))
  },
  async cancelMunicipality(municipality: Municipality) {
    const input = {
      id: municipality.municipalityId,
      sortKey: Municipality.sortKey,
      services: { concierge: false }
    }
    await API.graphql(graphqlOperation(updateMunicipality, { input: input }))
  },
  async enableUser(userId: string): Promise<void> {
    const init = {
      body: { username: userId },
      headers: await createHeaders()
    }
    await API.post(this.apiName, '/enableUser', init)
  },
  async disableUser(userId: string): Promise<void> {
    const init = {
      body: { username: userId },
      headers: await createHeaders()
    }
    await API.post(this.apiName, '/disableUser', init)
  },

  /**
   * 自治体切替
   * @param municipality 
   */
  switchMunicipality(municipality: Municipality|null) {
    const municipalityId = municipality?.municipalityId || ''
    const services = municipality?.getServices()
    Concierge.municipalityId = (services?.concierge) ? municipalityId : ''
  }
}