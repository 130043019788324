import CategoriesIndexView from '../views/categories/IndexView.vue'
import CategoriesNewView from '../views/categories/NewView.vue'
import CategoriesEditView from '../views/categories/EditView.vue'
import CategoriesImportView from '../views/categories/ImportView.vue'
import SectionsIndexView from '../views/sections/IndexView.vue'
import SectionsNewView from '../views/sections/NewView.vue'
import SectionsEditView from '../views/sections/EditView.vue'
import QuestionsIndexView from '../views/questions/IndexView.vue'
import QuestionsNewView from '../views/questions/NewView.vue'
import QuestionsEditView from '../views/questions/EditView.vue'
import ProcessesIndexView from '../views/processes/IndexView.vue'
import ProcessesImportView from '../views/processes/ImportView.vue'
import ProcessesNewView from '../views/processes/NewView.vue'
import ProcessesEditView from '../views/processes/EditView.vue'
import DepartmentsIndexView from '../views/departments/IndexView.vue'
import DepartmentsImportView from '../views/departments/ImportView.vue'
import DepartmentsNewView from '../views/departments/NewView.vue'
import DepartmentsEditView from '../views/departments/EditView.vue'
import SettingsView from '../views/SettingsView.vue'

export default {
  path: '/concierge',
  name: 'concierge',
  meta: { requiresAuth: true },
  children: [
    {
      path: 'categories',
      name: 'categories',
      meta: {
        title: '質問管理'
      },
      children: [
        { path: '', name: 'categories-index', alias: '/concierge', component: CategoriesIndexView },
        { path: 'new', name: 'categories-new', component: CategoriesNewView,
          meta: {
            title: 'カテゴリ登録'
          }
        },
        { path: ':categoryId', name: 'categories-edit',
          meta: {
            title: 'カテゴリ編集',
            breadCrumb: {
              skipRoute: true
            }
          },
          children: [
            { path: '', name: 'categories-edit-index', component: CategoriesEditView, props: true },
            {
              path: 'import',
              name: 'categories-import',
              component: CategoriesImportView,
              props: true,
              meta: {
                title: '質問データインポート'
              }
            },
            {
              path: 'sections',
              name: 'sections',
              meta: {
                title: 'セクション一覧'
              },
              children: [
                { path: '', name: 'sections-index', component: SectionsIndexView, props: true },
                { path: 'new', name: 'sections-new', component: SectionsNewView, props: true,
                  meta: {
                    title: 'セクション登録'
                  }
                },
                { path: ':sectionId', name: 'sections-edit',
                  meta: {
                    title: 'セクション編集',
                    breadCrumb: {
                      skipRoute: true
                    }
                  },
                  children: [
                    { path: '', name: 'sections-edit-index', component: SectionsEditView, props: true },
                    {
                      path: 'questions',
                      name: 'questions',
                      meta: {
                        title: '質問一覧'
                      },
                      children: [
                        { path: '', name: 'questions-index', component: QuestionsIndexView, props: true },
                        { path: 'new', name: 'questions-new', component: QuestionsNewView, props: true,
                          meta: {
                            title: '質問登録'
                          }
                        },
                        { path: ':questionId', name: 'questions-edit', component: QuestionsEditView, props: true,
                          meta: {
                            title: '質問編集',
                            breadCrumb: {
                              skipRoute: true
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: 'processes',
      name: 'processes',
      meta: {
        title: '手続き一覧'
      },
      children: [
        { path: '', name: 'processes-index', component: ProcessesIndexView },
        { path: 'import', name: 'processes-import', component: ProcessesImportView,
          meta: {
            title: '手続きインポート'
          }
        },
        { path: 'new', name: 'processes-new', component: ProcessesNewView,
          meta: {
            title: '手続き登録'
          }
        },
        { path: ':id', name: 'processes-edit', component: ProcessesEditView, props: true,
          meta: {
            title: '手続き編集'
          }
        },
      ],
    },
    {
      path: 'departments',
      name: 'departments',
      meta: {
        title: '担当課一覧'
      },
      children: [
        { path: '', name: 'departments-index', component: DepartmentsIndexView },
        { path: 'import', name: 'departments-import', component: DepartmentsImportView,
          meta: {
            title: '担当課インポート'
          }
        },
        { path: 'new', name: 'departments-new', component: DepartmentsNewView,
          meta: {
            title: '担当課登録'
          }
        },
        { path: ':id', name: 'departments-edit', component: DepartmentsEditView, props: true,
          meta: {
            title: '担当課編集'
          }
        },
      ],
    },
    {
      path: 'settings',
      name: 'settings',
      meta: {
        title: 'サイト管理'
      },
      component: SettingsView
    }
  ]
}
