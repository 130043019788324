import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_text = _resolveComponent("input-text")!

  return (_openBlock(), _createBlock(_component_input_text, _mergeProps({
    modelValue: _ctx.email,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event))
  }, _ctx.$attrs, {
    type: "email",
    pattern: _ctx.pattern,
    label: _ctx.label,
    required: _ctx.required
  }), null, 16, ["modelValue", "pattern", "label", "required"]))
}