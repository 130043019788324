import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_text = _resolveComponent("input-text")!

  return (_openBlock(), _createBlock(_component_input_text, _mergeProps({
    type: "url",
    modelValue: _ctx.url,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.url) = $event)),
    placeholder: "https://",
    required: _ctx.required,
    label: _ctx.label
  }, _ctx.$attrs, { pattern: _ctx.pattern }), null, 16, ["modelValue", "required", "label", "pattern"]))
}