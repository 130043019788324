import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["required"]
const _hoisted_2 = {
  key: 0,
  value: ""
}
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("select", _mergeProps({
      class: "form-select",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.select) = $event)),
      required: _ctx.required,
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validate && _ctx.validate(...args)))
    }, _ctx.$attrs), [
      (!_ctx.required)
        ? (_openBlock(), _createElementBlock("option", _hoisted_2, _toDisplayString(_ctx.emptyString), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("option", {
          key: index,
          value: _ctx.getValue(option)
        }, _toDisplayString(_ctx.getName(option)), 9, _hoisted_3))
      }), 128))
    ], 16, _hoisted_1), [
      [_vModelSelect, _ctx.select]
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.error), 1)
  ], 64))
}