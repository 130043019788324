import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store, storeKey } from './store'
import { Amplify } from 'aws-amplify'
import awsExports from './aws-exports'
import nicotto from './plugins/nicotto'
import message from './plugins/message'

// ログレベル
Amplify.Logger.LOG_LEVEL = process.env.VUE_APP_LOG_LEVEL

// S3モックを止めてもモック用の設定が残るバグ対策(AWSにデプロイ時はモック用の設定をfalseにする)
// Ref. https://github.com/aws-amplify/amplify-cli/issues/11493
if (process.env.NODE_ENV == 'production') {
  awsExports.aws_user_files_s3_dangerously_connect_to_http_endpoint_for_testing = false
}

Amplify.configure(awsExports)

// 認証情報をセッションストレージに保存(標準ではローカルストレージ)
Amplify.configure({ Auth: { storage: window.sessionStorage } })

createApp(App)
  .use(store, storeKey)
  .use(router)
  .use(nicotto)
  .use(message)
  .mount('#app')
