import { format } from 'util'
import { ref } from "vue"

interface Params {
  value: string,
  label: string,
  required: boolean,
  pattern?: string|null,
  invalidPatternMessage?: string
}

export const useValidator = () => {
  const error = ref('')
  const validateText = ({ value, label, required, pattern, invalidPatternMessage }: Params): void => {
    error.value = ''
    if (value == '' || value === null) {
      if (required) {
        error.value = label + 'を入力してください'
      }
    } else if (pattern && ! new RegExp(pattern).test(value)) {
      const message = invalidPatternMessage || '%sの形式が正しくありません'
      error.value = format(message, label)
    }
  }
  const validateSelect = ({ value, label, required }: Params): void => {
    error.value = ''
    if (value == '') {
      if (required) {
        error.value = label + 'を選択してください'
      }
    }
  }

  return {
    error,
    validateText,
    validateSelect
  }
}

export const useValidateForm = (form: HTMLFormElement) => {
  form.classList.add('was-validated')
  form.querySelectorAll('.form-control,.form-select')
    .forEach((el) => {
      el.dispatchEvent(new Event('blur'))
      el.dispatchEvent(new Event('change'))
    })

  return {
    isValid: () => {
      return !form.querySelector(':invalid')
    }
  }
}