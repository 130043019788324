
import { defineComponent, h, resolveComponent } from 'vue'
import IconCircleQuestion from '../ui/IconCircleQuestion.vue'

export default defineComponent({
  components: { IconCircleQuestion },
  props: {
    required: { type: Boolean, default: false },
    label: { type: String, default: '' },
    labelFor: { type: String, default: '' },
    labelClass: { type: [Array, Object, String], default: '' },
    horizontal: { type: Boolean, default: true },
    helpLink: { type: String, default: ''}
  },
  render() {
    const required = this.required ? h('span', { class: 'badge bg-danger required' }, '必須') : ''
    const IconCircleQuestionComponent = resolveComponent('IconCircleQuestion')
    const helpLink = this.helpLink ? h('a', { class: '', target: '_blank', href: this.helpLink, title: 'ヘルプページ' }, h(IconCircleQuestionComponent)) : ''
    const labelAtters = {
      class: [
        this.horizontal ? 'col-form-label col-sm-2' : 'form-label',
        this.labelClass
      ],
      for: this.labelFor
    }
    const label = h('label', labelAtters, [this.label, helpLink, required])

    const slotDefalut = (this.$slots.default)
      ? this.$slots.default()
      : undefined

    if (this.horizontal) {
      return h('div', { class: 'row form-group' }, [
        label,
        h('div', { class: 'col' }, slotDefalut)
      ])
    } else {
      return h('div', { class: 'form-group mb-3' }, [label, slotDefalut])
    }
  }
})
