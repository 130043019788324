import { Auth as AmplifyAuth, Logger } from 'aws-amplify'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Buffer } from 'buffer'
import { Account, buildUserData, User } from './account'
import { Concierge } from './concierge'
import { signUpConfirmInput } from './types'

const logger = new Logger('auth')

export const Auth = {
  generatePassword(pwLength = 16) {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let pw = ''
    for (let i = 0; i <= pwLength; i++) {
      const pos = Math.floor(Math.random() * chars.length)
      pw += chars.substring(pos, pos + 1)
    }
    return pw
  },
  async getCurrentUser() {
    try {
      const cognitoUser = await AmplifyAuth.currentAuthenticatedUser()
      const data = await buildUserData(await this.getUserAttributes(cognitoUser))
      const user = new User(cognitoUser.username, data, cognitoUser)

      // 自治体ユーザの場合、サービス利用状況に合わせて機能を有効化
      if (user.municipality) {
        const municipalityId = user.municipality.municipalityId
        const services = user.municipality.getServices()
        // 手続きコンシェルジュ
        if (services.concierge) {
          Concierge.municipalityId = municipalityId
        }
      }
      return user
    } catch {
      return null
    }
  },
  async getUserAttributes(cognitoUser: CognitoUser) {
    return await AmplifyAuth.userAttributes(cognitoUser)
  },
  async signUp(email: string) {
    // Cognitoのユーザー登録時はパスワードが必須なのでランダムパスワードを設定
    const password = this.generatePassword()
    try {
      return await AmplifyAuth.signUp(email, password)
    } catch (err:any) {
      if (err.code === 'UsernameExistsException') {
        return
      } else {
        logger.error('signUp', err)
        throw err
      }
    }
  },
  async resendSignUp(email: string) {
    return await AmplifyAuth.resendSignUp(email)
  },
  async signUpConfirm(payload: signUpConfirmInput) {
    const {email, code, password, ...data} = payload
    data.prefecture = String(data.prefecture) // 数値の可能性もあるので文字列に変換
    const encodedPassword = Buffer.from(password, 'utf8').toString('base64')
    return await AmplifyAuth.confirmSignUp(email, code, {
      clientMetadata: { ...data as any, pw: encodedPassword }
    })
  },
  async signIn(email: string, password: string): Promise<User> {
    const cognitoUser = await AmplifyAuth.signIn(email, password)
    logger.info('signIn', cognitoUser)
    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      // パスワード変更要求が発生するのは新規管理者アカウント登録時のみなので、変更せずに再設定
      await AmplifyAuth.completeNewPassword(cognitoUser, password)
    }
    const user = await this.getCurrentUser() as unknown as User
    logger.info('認証成功', user)
    return user
  },
  async signOut() {
    await AmplifyAuth.signOut()
  },
  async forgotPassword(email: string) {
    try {
      await AmplifyAuth.forgotPassword(email)
    } catch (err) {
      logger.error('forgotPassword', err)
      throw err
    }
  },
  async forgotPasswordSubmit(email: string, code: string, new_password: string) {
    try {
      await AmplifyAuth.forgotPasswordSubmit(email, code, new_password)
    } catch (err) {
      logger.error('forgotPasswordSubmit', err)
      throw err
    }
  },
  async updateAccount(user: User) {
    if (user.isAdmin() || !user.municipality) {
      throw new Error("管理者アカウントはこのメソッドで更新できません。")
    }
    await Account.updateAccount(user)
  },
  async updateEmail(email: string) {
    const user = await AmplifyAuth.currentAuthenticatedUser()
    if (!user) {
      throw new Error('User not authenticate.')
    }
    return await AmplifyAuth.updateUserAttributes(user, { email })
  },
  async resendEmailVerificationCode() {
    return await AmplifyAuth.verifyCurrentUserAttribute('email')
  },
  async completeEmailChange(code: string) {
    const user = await AmplifyAuth.currentAuthenticatedUser()
    if (!user) {
      throw new Error('User not authenticate.')
    }
    return await AmplifyAuth.verifyUserAttributeSubmit(user, 'email', code)
  },
  async updatePassword(currentPassword:string, newPassword: string) {
    const user = await AmplifyAuth.currentAuthenticatedUser()
    if (!user) {
      throw new Error('User not authenticate.')
    }
    return await AmplifyAuth.changePassword(user, currentPassword, newPassword)
  },
}